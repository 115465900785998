import { useState } from "react"
import { SchedualInterface } from "../../../Interfaces/Entities"
import { ListUserAgendaInterface } from "../../../Interfaces/ComponentsInterfaces/SharedInterfaces"
import ViewIndividualAgenda from "../ViewData/ViewIndividualAgenda"
import { PrepareScheduleForUser } from "../../../Helpers/PrepareHelpers"
import "../SharedComponents.css"


function ListUserAgenda({ userToShow }: ListUserAgendaInterface) {

	const [scheduleOfUser, setScheduleOfUser] = useState<SchedualInterface>(PrepareScheduleForUser(userToShow.schedule))

	return (
		<div className="ListUserAgendamMainDiv">
			<h3>Mi horario</h3>
			<div className="MapAgendaByDayDiv">
				{Object.keys(scheduleOfUser).map((day: string) => (
					<ViewIndividualAgenda day={day} userAgenda={scheduleOfUser} setUserAgenda={setScheduleOfUser} />
				))}
			</div>
		</div>
	)
}

export default ListUserAgenda