import { ModalTypes } from "../Interfaces/ContextInterfaces/GeneralContext"
import { CommentInterface, NoticeInterface, SchoolInterface } from "../Interfaces/Entities"
import { AreTwoDatesTheSameDay, CreateDateFromNoticeDate, isDateInThisWeek } from "./DateHelpers"


export function TranslateDayOfWeek(dayOfWeek: string): string {
	switch (dayOfWeek) {
		case "monday":
			return "Lunes"
		case "tuesday":
			return "Martes"
		case "wednesday":
			return "Miercoles"
		case "thursday":
			return "Jueves"
		case "friday":
			return "Viernes"
		default:
			return ""
	}
}


export function TranslateModalName(modalCode: ModalTypes['type']): string {
	switch (modalCode) {
		case 'HomeModal':
			return 'Entrada'
		case 'NoticeList':
			return 'Historial'
		case 'SeeNotice':
			return 'Historial'
		case 'CreateNotice':
			return 'Nuevo aviso'
		case 'CreateNewsLetter':
			return 'Nueva nota'
		case 'GroupList':
			return 'Mis grupos y prioridades'
		case 'SeeGroup':
			return 'Mis grupos y prioridades'
		case 'Profile':
			return 'Mi cuenta'
		case 'HomeReport':
			return 'Informes'
		case 'GetIndividualReport':
			return 'Informes'
		case 'GetGrupalReport':
			return 'Informes'
		case 'EmergencyTypes':
			return 'Situaciones críticas'
		case 'StudentsAndUsers':
			return 'Usuarios y Alumnos'
		case 'AllGroupList':
			return 'Grupos'
		case 'CreateGroup':
			return 'Crear grupo'
		case 'CreateStudent':
			return 'Crear nuevo alumno'
		case 'CreateUser':
			return 'Crear nuevo usuario'
		case 'EditUser':
			return 'Usuario'
		case 'EditStudent':
			return 'Estudiante'
		case 'SchoolList':
			return 'Escuelas'
		case 'SchoolCreater':
			return 'Crear nueva escuela'
		case 'SchoolEdit':
			return 'Editar escuela'
		default:
			return 'Entrada';
	}
}


export function TranslateNoticeDate(notice: NoticeInterface): string {
	const today = new Date();
	const noticeDate = CreateDateFromNoticeDate(notice.date)
	if (AreTwoDatesTheSameDay(today, noticeDate)) {
		if (notice.date.includes(" ")) {
			return `Hoy, ${notice.date.split(" ")[1].split(":")[0]}:${notice.date.split(" ")[1].split(":")[1]}`  
		} else {
			return 'Hoy';
		}
	}
	const yesterday = new Date(today);
	yesterday.setDate(today.getDate() - 1);
	if (AreTwoDatesTheSameDay(yesterday, noticeDate)) {
		return 'Ayer';
	}
	if (isDateInThisWeek(notice.date)) {
		return 'Esta semana';
	}
	if(notice.date.includes(" ")) {
		const [year, month, day] = notice.date.split(" ")[0].split("-");
		return `${day}-${month}`
	}
	return notice.date
}


export function TranslateCommentDate(comment: CommentInterface): string {
	const today = new Date();
	if (!(comment.ts)) {
		return `Hoy, ${today.getHours()}:${today.getMinutes().toString().padStart(2, '0')}` 
	}
	const noticeDate = CreateDateFromNoticeDate(comment.ts)
	if (AreTwoDatesTheSameDay(today, noticeDate)) {
		if (comment.ts.includes(" ")) {
			return `Hoy, ${comment.ts.split(" ")[1].split(":")[0]}:${comment.ts.split(" ")[1].split(":")[1]}`  
		} else {
			return 'Hoy';
		}
	}
	const yesterday = new Date(today);
	yesterday.setDate(today.getDate() - 1);
	if (AreTwoDatesTheSameDay(yesterday, noticeDate)) {
		return 'Ayer';
	}
	if (isDateInThisWeek(comment.ts)) {
		return 'Esta semana';
	}
	if(comment.ts.includes(" ")) {
		const [year, month, day] = comment.ts.split(" ")[0].split("-");
		if (year.length === 4) {
			return `${day}-${month}`
		}
		return `${year}-${month}`
	}
	return comment.ts
}

export function TranslateClassnameForNotice(notice: NoticeInterface, type: string, school: SchoolInterface | undefined): 
string {
	const noticeTypeTranslation = TranslateNoticeType(notice.type, school)
	return `${noticeTypeTranslation}${type}ColorCode`
}

export function TranslateClassnameForButton(emergencyType: string, school: SchoolInterface | undefined): string {
	const noticeTypeTranslation = TranslateNoticeType(emergencyType, school)
	return `${noticeTypeTranslation}UnResolvedColorCode`
}

function TranslateNoticeType(noticeType: string, school: SchoolInterface | undefined): string {
	const defaultEmergencies = ['Salud Mental', 'Enfermeria', 'Agresión', 'Convivencia',
		'Extravio Estudiantil', 'Asistencia higiénica', 'Asistencia técnica', 'General']
	if (defaultEmergencies.includes(noticeType)) {
		return TranslateBasicNoticeTypes(noticeType)
	}
	const schoolEmergencies = school ? school.types : [];
	const newEmergencies = schoolEmergencies.filter(item => !defaultEmergencies.includes(item))
	const specialTranslations = ['FirstSpecial', 'SecondSpecial', 'ThirdSpecial', 'FourthSpecial']
	for (let iteration = 0; iteration < specialTranslations.length; iteration++) {
		const currentTranslation = specialTranslations[iteration];
		if (noticeType === newEmergencies[iteration]) {
			return currentTranslation
		}
	}
	return specialTranslations[0]
}

function TranslateBasicNoticeTypes(type: string): string {
	switch (type) {
		case "Salud Mental":
			return 'MentalHealth'
		case 'Enfermeria':
			return 'Nursing'
		case 'Agresión':
			return 'Agretion'
		case 'Convivencia':
			return 'Coexistence'
		case 'Extravio Estudiantil':
			return 'MissingStudent'
		case 'Asistencia higiénica':
			return 'Hygienic'
		case 'Asistencia técnica':
			return 'Tecnic'
		case 'General':
			return 'General'
		default:
			return 'General'
	}
}


export function TranslateUserName(userCode: string) : string {
	if (userCode.includes("@")) {
		return userCode.split("@")[0]
	}
	return userCode
}

export function TranslateDeletionType(deletionType: string) : string {
	switch (deletionType) {
		case 'student':
			return 'estudiante'
		case 'group':
			return 'grupo'
		case 'user':
			return 'usuario'
		case 'school':
			return 'colegio'
		default:
			return ''
	}
}

export function TranslatePriorityLabel(stringValue : string) : string {
	switch (stringValue) {
		case 'high':
			return 'Alta'
		case 'mid':
			return 'Media'
		case 'always':
			return 'Prioritaria'
		default:
			return 'baja'
	}
}

export function TranslateSelecterText(currentModal: ModalTypes['type']): string {
	switch (currentModal) {
		case "SeeNotice":
			return 'NoticeList'
		case "SeeGroup":
			return 'GroupList'
		case "GetIndividualReport":
			return 'HomeReport'
		case "GetGrupalReport":
			return 'HomeReport'
		case "SchoolEdit":
			return 'SchoolList'
		case "SchoolCreater":
			return 'SchoolList'
		case "CreateGroup":
			return 'AllGroupList'
		case "SeeGroupAdmin":
			return 'AllGroupList'
		case "CreateUser":
			return 'StudentsAndUsers'
		case "CreateStudent":
			return 'StudentsAndUsers'
		case "EditUser":
			return 'StudentsAndUsers'
		case "EditStudent":
			return 'StudentsAndUsers'
		default:
			return currentModal
	}
}


export function GetRedirectURL(adminValue: string, userSchool: string) : string {
	switch (adminValue) {
		case 'SuperAdmin':
			return '/admin'
		case 'SchoolAdmin':
			return `/admin/colegio/${userSchool}`
		default:
			return `/colegio/${userSchool}`
	}
}

export function TranslatePasswordError(errorMessage : string): string {
	switch (errorMessage) {
		case 'challengeResponse is required to confirmSignIn':
		  return 'No puedes usar una contraseña vacía';
		case 'Password does not conform to policy: Password not long enough':
		  return 'La contraseña debe tener al menos 8 caracteres';
		case 'Password does not conform to policy: Password must have lowercase characters':
		  return 'La contraseña debe tener al menos una letra minúscula';
		case 'Password does not conform to policy: Password must have uppercase characters':
		  return 'La contraseña debe tener al menos una letra mayúscula';
		case 'Password does not conform to policy: Password must have symbol characters':
		  return 'La contraseña debe tener al menos un símbolo';
		default:
		  return 'Hay un problema con tu contraseña';
	  }
}