import React, { useState } from "react"
import { updatePassword } from "aws-amplify/auth"
import { EditPasswordInterface } from "../../../Interfaces/ComponentsInterfaces/SharedInterfaces"
import { CheckIfEditPasswordIsComplete } from "../../../Helpers/CheckHelpers"
import '../SharedComponents.css'

function EditPassword({ userToShow }: EditPasswordInterface) {

	const [oldPassword, setOldPassword] = useState<string>('')
	const [newPassword, setNewPassword] = useState<string>('')
	const [confirmNewPassword, setConfirmNewPassword] = useState<string>('')
	const [isRevealingOldPassword, setIsRevealingOldPassword] = useState<boolean>(false)
	const [isRevealingNewPassword, setIsRevealingNewPassword] = useState<boolean>(false)
	const [isRevealingConfirmPassword, setIsRevealingConfirmPassword] = useState<boolean>(false)
	const [isBoxOpen, setIsBoxOpen] = useState<boolean>(false)

	const handleOldPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault()
		setOldPassword(e.target.value)
	}

	const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault()
		setNewPassword(e.target.value)
	}

	const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault()
		setConfirmNewPassword(e.target.value)
	}

	const handleConfirmPassword = async () => {
		if (newPassword !== confirmNewPassword) {
			alert('Las contraseñas no coinciden')
			return
		}
		try {
			await updatePassword({
				oldPassword: oldPassword,
				newPassword: newPassword,
			})
			alert("Contraseña correctamente cambiada")
		} catch (error) {
			alert("Hubo un problema al cambiar la contraseña")
		}

	}

	const handleOpenButton = () => {
		if (isBoxOpen) {
			setNewPassword('')
			setOldPassword('')
			setIsBoxOpen(false)
		} else{ 
			setIsBoxOpen(true)
		}
	}

	return (
		<div className="EditPassWordMainDiv">
			<div className="EditPasswordTopDiv">
				<h4>Cambiar Contraseña</h4>
				<button type='button' onClick={handleOpenButton}>
					{isBoxOpen ? 'Cancelar' : 'Cambiar contraseña'}
				</button>
			</div>
			{isBoxOpen && (
				<>
					<div className="EditPasswordShowPasswords">
						<div className="IndividualPassword">
							<label>Contraseña actual:</label>
							<input
								type={isRevealingOldPassword ? "text" : "password"}
								id='oldPassword'
								name='oldPassword'
								value={oldPassword}
								onChange={handleOldPasswordChange}
								required={true}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										e.preventDefault();
									}
								}}
							/>
							<button type="button" onClick={() => setIsRevealingOldPassword(!isRevealingOldPassword)}>
								{isRevealingOldPassword ? "Ocultar" : "Mostrar"}
							</button>
						</div>
						<div className="IndividualPassword">
							<label>Nueva contraseña:</label>
							<input
								type={isRevealingOldPassword ? "text" : "password"}
								id='newPassword'
								name='newPassword'
								value={newPassword}
								onChange={handleNewPasswordChange}
								required={true}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										e.preventDefault();
									}
								}}
							/>
							<button type="button" onClick={() => setIsRevealingNewPassword(!isRevealingNewPassword)}>
								{isRevealingNewPassword ? "Ocultar" : "Mostrar"}
							</button>
						</div>
						<div className="IndividualLastPassword">
							<label>Confirme su nueva contraseña:</label>
							<input
								type={isRevealingConfirmPassword ? "text" : "password"}
								id='confirmPassword'
								name='confirmPassword'
								value={confirmNewPassword}
								onChange={handleConfirmPasswordChange}
								required={true}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										e.preventDefault();
									}
								}}
							/>
							<button type="button" onClick={() => setIsRevealingConfirmPassword(!isRevealingNewPassword)}>
								{isRevealingNewPassword ? "Ocultar" : "Mostrar"}
							</button>
						</div>
					</div>
					<div className="EditPassWordButtonDiv">
						<button 
							type="button" 
							onClick={handleConfirmPassword} 
							disabled={!CheckIfEditPasswordIsComplete(oldPassword, newPassword, confirmNewPassword)}
						>
							Confirmar nueva contraseña
						</button>
					</div>
				</>
			)}
		</div>
	)
}

export default EditPassword