import { useEffect, useState } from "react";
import { useSchoolAdminModalContext } from "../../../Contexts/SchoolAdminContext/SchoolAdminProvider";
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider";
import { StudentInterface } from "../../../Interfaces/Entities";
import { ListGroupStudentsAdminInterface } from "../../../Interfaces/ComponentsInterfaces/SchoolAdminInterfaces";
import SearchStudentAdmin from "../SearchData/SearchStudentAdmin";
import { FetchPutFunction } from "../../../Helpers/FetchHelpers";
import TrashBinIcon from '../../../Assets/TrashBinIcon.png'
import '../../SharedComponents/SharedComponents.css'
import '../SchoolAdmin.css'
import '../../../index.css'
import { useUserModalContext } from "../../../Contexts/UserModalContext/UserModalProvider";



function ListGroupStudentsAdmin({ }: ListGroupStudentsAdminInterface) {

	const { schoolGroups, setSchoolGroups } = useSchoolAdminModalContext()
	const { groupToSee } = useUserModalContext()
	const { schoolOfUser } = useGeneralContext()
	const [ groupStudents, setGroupStudents] = useState<StudentInterface[]>(groupToSee.students ? groupToSee.students : [])
	const [ isMounted, setIsMounted] = useState<boolean>(false)

	const updateStudentsWithBackend = async () => {
		try {
			if (!schoolOfUser) {
				throw new Error("schoolOfUser no está definido");
			}
			const url = `${process.env.REACT_APP_BACKEND_URL}/groups/${groupToSee.SK}`
			const body = {
				"school_id": schoolOfUser.SK,
				"students": groupStudents
			}
			await FetchPutFunction(url, body)
		} catch (error) {
			console.log("Error al actualizar estudiantes:", error);
		}
	}

	const updateInternalGroups = () => {
		const updatedSchoolGroups = schoolGroups.map((group) => (
			group.SK === groupToSee.SK ? { ...groupToSee, students: groupStudents } : group
		))
		setSchoolGroups(updatedSchoolGroups)
	}

	useEffect(() => {
		const fetchData = async () => {
			if (isMounted) {
				await updateStudentsWithBackend();
			} else {
				setIsMounted(true);
			}
		};

		fetchData();
		updateInternalGroups()
	}, [groupStudents])

	useEffect(() => {
		setGroupStudents(groupToSee.students)
	}, [groupToSee])

	const handleSearchClick = (student: StudentInterface) =>
	{
		if (!groupStudents.some(groupStudent => groupStudent.rut === student.rut)) {
			setGroupStudents([...groupStudents, student])
		}
	}

	const handleDelete = (indexToDelete: number) => {
		const updatedStudentList = groupStudents.filter((_, index) => index !== indexToDelete);
		setGroupStudents(updatedStudentList)
	}

	return (
		<div className="ListGroupStudentMainDiv">
			<h4>Estudiantes</h4>
			<SearchStudentAdmin 
				groupStudents={groupStudents}
				handleSearchClick={handleSearchClick}
			/>
			{groupStudents.length === 0 ? (
				<div className="ListUserEmptyList">
					<h4>Este grupo no tiene estudiantes</h4>
				</div>
			) : (
				<div>
					{groupStudents.map((student: StudentInterface, index: number) => (
						<div className="IndividualStudentDiv">
							<h4 className="IndexColumn">{index}</h4>
							<h4 className="NameColumn">{student.name}</h4>
							<button className="DeletionButton" type='button' onClick={() => handleDelete(index)}>
								<img src={TrashBinIcon} alt="DeleteIcon"/>
							</button>
						</div>
					))}
				</div>
			)}
		</div>
	)
}

export default ListGroupStudentsAdmin