import React, { useEffect, useRef, useState } from "react";
import { Navbar } from "react-bootstrap";
import { useGeneralContext } from "../../Contexts/GeneralContext/GeneralProvider";
import { useUserModalContext } from "../../Contexts/UserModalContext/UserModalProvider";
import { NoticeInterface } from "../../Interfaces/Entities";
import { GetDayFromNow } from "../../Helpers/DateHelpers";
import { TranslateClassnameForNotice } from "../../Helpers/TranslateHelpers";
import defaultProfile from '../../Assets/BasicUserIcon.png';
import MoonIcon from '../../Assets/MoonIcon.png'
import SunIcon from '../../Assets/SunIcon.png'
import "./NavBar.css";
import LoadingInfo from "../../Components/SharedComponents/OtherComponents/LoadingInfo";


function NavBar() {

	const { user, turnOffSleepMode, sendDateToBackEnd, schoolOfUser, setCurrentModal } = useGeneralContext();
	const { allNotices, setNoticeToSee } = useUserModalContext();

	const [currentSearch, setCurrentSearch] = useState<string>('');
	const inputRef = useRef<HTMLInputElement>(null);
	const searchResultsRef = useRef<HTMLDivElement>(null);
	const [filteredNotices, setFilteredNotices] = useState<NoticeInterface[]>([]);
	const [popupStyle, setPopupStyle] = useState<{ left: number; top: number; width: number } | null>(null);
	const [sleepIcon, setSleepIcon] = useState<'Modo Off' | 'Modo On'>('Modo Off')

	const setSleepMode = async () => {
		if (!user) {
			return
		}
		if (user.off_mode_until === undefined || user.off_mode_until?.trim() === '') {
			await addOneDayToSleepMode();
		} else {
			const userCurrentOffDate = new Date(user.off_mode_until);
			if (userCurrentOffDate < new Date()) {
				await addOneDayToSleepMode();
			} else {
				await turnOffSleepMode();
			}
		}
	};

	const addOneDayToSleepMode = async () => {
		const formattedDate = GetDayFromNow(1);
		await sendDateToBackEnd(formattedDate);
	};

	const getSleepModeButtonLabel = () => {
		if (!user) {
			return 'Modo Off'
		}
		if (user.off_mode_until === undefined || user.off_mode_until?.trim() === '') {
			return "Modo Off";
		} else {
			const userCurrentOffDate = new Date(user.off_mode_until);
			if (userCurrentOffDate < new Date()) {
				return "Modo Off";
			} else {
				return "Modo On";
			}
		}

	};

	useEffect(() => {
		const newText = getSleepModeButtonLabel()
		setSleepIcon(newText)
	}, [user])

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const searchValue = e.target.value;
		setCurrentSearch(searchValue);

		if (searchValue.trim() === '') {
			setFilteredNotices([]);
		} else {
			const filtered = allNotices.filter((notice) =>
				notice.description.toLowerCase().includes(searchValue.toLowerCase()) ||
				(notice.title && notice.title.toLowerCase().includes(searchValue.toLowerCase()))
			);
			setFilteredNotices(filtered);
		}
	};

	useEffect(() => {
		const inputElement = inputRef.current;
		if (inputElement) {
			const rect = inputElement.getBoundingClientRect();
			setPopupStyle({
				left: rect.left + 18,
				top: rect.bottom + window.scrollY,
				width: rect.width - 39
			});
		}
	}, [currentSearch]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				searchResultsRef.current &&
				!searchResultsRef.current.contains(event.target as Node) &&
				inputRef.current &&
				!inputRef.current.contains(event.target as Node)
			) {
				setFilteredNotices([]);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [searchResultsRef]);

	const handleSearchClick = (notice: NoticeInterface) => {
		setNoticeToSee(notice)
		setCurrentModal('SeeNotice')
		setCurrentSearch('')
		setFilteredNotices([])
	}


	return (
		<Navbar className="LinkClassNavBar">
			<div className="LinkClassNavBarSubDiv">
				<input
					ref={inputRef}
					value={currentSearch}
					onChange={handleSearchChange}
					placeholder="Buscar..."
					className="NavBarSearchInput"
					autoComplete='new-password'
					name="searchNotice"
				/>


				{filteredNotices.length > 0 && popupStyle && (
					<div
						className="NavBarSearchResults"
						style={{ left: popupStyle.left, top: popupStyle.top, width: popupStyle.width }}
						ref={searchResultsRef}
					>
						<ul className="SearchResultsList">
							{filteredNotices.map((notice, index) => (
								<li key={index}
									className={`SearchResultItem ${TranslateClassnameForNotice(notice,
										'Search', schoolOfUser)}`}
									onClick={() => handleSearchClick(notice)}>
									{notice.title ? notice.title : notice.description}
								</li>
							))}
						</ul>
					</div>
				)}

				<div className="NavBarLoggedDiv">
					<button onClick={setSleepMode}>
						{sleepIcon === 'Modo Off' ? (
							<img src={MoonIcon} alt="MoonIcon" />
						) : (
							<img src={SunIcon} alt='SunIcon' />
						)}
					</button>
					<div className="NavBarLoggedRightDiv" onClick={() => setCurrentModal('Profile')}>
						{user ? (
							<>
								<img src={user.profile_image ? user.profile_image : defaultProfile} alt="logo" />
								{user.name}
							</>
						) : (
							<LoadingInfo/>
						)}

					</div>
				</div>
			</div>
		</Navbar>
	);
}

export default NavBar
