import { useUserModalContext } from "../../../Contexts/UserModalContext/UserModalProvider"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import ViewSingularNotification from "../ViewData/ViewSingularNotification"
import '../SharedComponents.css'


function GroupHomeNotices() {

	const { resolvedNotices, unResolvedNotices } = useUserModalContext()
	const { setCurrentModal } = useGeneralContext()

	return (
		<>
			<div className="GroupHomeUnResolvedNotices FirstAlertDiv">
				<div className="GroupHomeNoticesTitles">
					<h4>Notificaciones pendientes</h4>
					<button type='button' onClick={() => setCurrentModal('NoticeList')}>
						Ver Todas
					</button>
				</div>
				<div className="GroupHomeUnResolvedNoticeData">
					{unResolvedNotices.length === 0 ? (
						<h1>No hay avisos hoy</h1>
					) : (
						unResolvedNotices.slice(0, 2).map((notice) => (
							<ViewSingularNotification
								noticeToShow={notice}
								notificationType="UnResolved"
								boxSize="SmallerNotification"
							/>
						))
					)}
				</div>
			</div>
			<div className="GroupHomeResolvedNotices">
				<div className="GroupHomeNoticesTitles NotFirstAlertDiv">
					<h4>Notificaciones resueltas</h4>
					<button type='button' onClick={() => setCurrentModal('NoticeList')}>
						Ver Todas
					</button>
				</div>
				<div className="GroupHomeResolvedNoticeData">
					{resolvedNotices.length >= 3 ? (
						<>
							<div className="GroupHomeResolvedNoticeInternalData1">
								{resolvedNotices.slice(0, 2).map((notice) => (
									<ViewSingularNotification
										noticeToShow={notice}
										notificationType="Resolved"
										boxSize="SmallerNotification"
									/>
								))}
							</div>
							<div className="GroupHomeResolvedNoticeInternalData2">
								{resolvedNotices.slice(2, 4).map((notice) => (
									<ViewSingularNotification
										noticeToShow={notice}
										notificationType="Resolved"
										boxSize="SmallerNotification"
									/>
								))}
							</div>
						</>
					) : (
						resolvedNotices.length === 0 ? (
							<h1>No hay avisos resueltos</h1>
						) : (
							<div className="GroupHomeResolvedNoticeInternalData1">
								{resolvedNotices.slice(0, 2).map((notice) => (
									<ViewSingularNotification
										noticeToShow={notice}
										notificationType="Resolved"
										boxSize="SmallerNotification"
									/>
								))}
							</div>
						)
					)}
				</div>
			</div>
		</>
	)
}

export default GroupHomeNotices