import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import { useSchoolAdminModalContext } from "../../../Contexts/SchoolAdminContext/SchoolAdminProvider"
import { MapUserInterface } from "../../../Interfaces/ComponentsInterfaces/SchoolAdminInterfaces"
import TrashBinIcon from '../../../Assets/TrashBinIcon.png'

function MapUser({ user, index }: MapUserInterface) {

	const { setUserToEdit } = useSchoolAdminModalContext()
	const { setCurrentModal } = useGeneralContext()

	const handleEditUser = () => {
		setUserToEdit(user)
		setCurrentModal('EditUser')
	}

	return (
		<tr>
			<td>{index}</td>
			<td>{user.name}</td>
			<td>{user.SK}</td>
			<td>
				<button type='button' onClick={handleEditUser}>
					Ver
				</button>
			</td>
			<td>
				<button type='button'>
					<img src={TrashBinIcon} alt="deleteButton"/>
				</button>
			</td>
		</tr>
	)
}

export default MapUser