import React, { useState } from "react"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import { useAdminModalContext } from "../../../Contexts/AdminModalContext/AdminModalProvider"
import { EditSchoolComponentInterface } from "../../../Interfaces/ComponentsInterfaces/AdministratorInterfaces"
import { BodyEdit, SchoolInterface } from "../../../Interfaces/Entities"
import { PrepareFileToSend } from "../../../Helpers/PrepareHelpers"
import { FetchPutFunction } from "../../../Helpers/FetchHelpers"
import defaultSchoolLogo from '../../../Assets/BasicSchoolLogo.png'


function EditSchool({ schoolToEdit }: EditSchoolComponentInterface) {

	const { adminSchools, setAdminSchools } = useAdminModalContext()
	const { setCurrentModal } = useGeneralContext()
	const [schoolNewData, setSchoolNewData] = useState<SchoolInterface>(schoolToEdit)
	const [isEditingLogo, setIsEditingLogo] = useState<boolean>(false)
	const [newLogo, setNewLogo] = useState<File | undefined>(undefined)
	const [logoPreview, setLogoPreview] = useState<string | undefined>(undefined);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setSchoolNewData({
			...schoolNewData,
			[name]: value,
		})
	}

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			const objectUrl = URL.createObjectURL(file);
			setNewLogo(file);
			setLogoPreview(objectUrl);
			setIsEditingLogo(false)
		}
	};

	const sendEditToBackend = async () => {
		try {
			const url = `${process.env.REACT_APP_BACKEND_URL}/schools/${schoolToEdit.SK}`
			const body: BodyEdit = {}
			if (newLogo) {
				const filePromises = await PrepareFileToSend(newLogo);
				filePromises['name'] = `${filePromises['name']}-profile-image-${schoolToEdit.SK}`
				body['files'] = [filePromises]
			}
			body['attrs'] = {
				"name": schoolNewData.name,
				"address": schoolNewData.address
			}
			await FetchPutFunction(url, body)
			alert('Escuela editada correctamente, recuerde actualizar los colegios para notar los cambios')
			updateSchoolsInContext()
			setCurrentModal('SchoolList')
		} catch (error) {
			alert('Hubo un error al editar el colegio')
		}
	};

	const updateSchoolsInContext = () => {
		const updatedSchools = adminSchools.map((school) =>
			school.SK === schoolToEdit.SK ? {
				...school, name: schoolNewData.name || school.name,
				address: schoolNewData.address || school.address
			}
				: school)
		setAdminSchools(updatedSchools)
	};

	return (
		<div className='EditSchoolMainDiv'>
			<div className="EditSchoolImageDiv">
				{isEditingLogo ? (
					<input
						type="file"
						accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.txt,.HEIF"
						onChange={handleFileChange}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								e.preventDefault();
							}
						}}
					/>
				) : (
					newLogo ? (
						<img src={logoPreview} alt='Logo por defecto' />
					) : (
						(schoolToEdit.profile_image && schoolToEdit.profile_image.trim() !== '') ? (
							<img src={schoolToEdit.profile_image} alt='Logo por defecto' 
								onClick={() => setIsEditingLogo(true)} />
						) : (
							<img src={defaultSchoolLogo} alt='Logo por defecto'  onClick={() => setIsEditingLogo(true)} />
						)
					)
				)}
				<button type='button' onClick={() => setIsEditingLogo(!isEditingLogo)}>
					{isEditingLogo ? 'Cancelar' : 'Editar Foto'}
				</button>
			</div>
			<div className="EditSchoolInputDiv">
				<label>Nombre:</label>
				<input
					type='text'
					value={schoolNewData.name}
					name="name"
					onChange={handleInputChange}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
			</div>
			<div className="EditSchoolInputDiv">
				<label>Dirección:</label>
				<input
					type='text'
					name="address"
					value={schoolNewData.address}
					onChange={handleInputChange}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
			</div>
			<div className="EditSchoolButtonDiv">
				<button type='button' onClick={sendEditToBackend}>
					Guardar cambios
				</button>
				<button type='button' onClick={() => setCurrentModal('SchoolList')}>
					Cancelar
				</button>
			</div>
		</div>
	)
}

export default EditSchool