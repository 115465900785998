import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import { useUserModalContext } from "../../../Contexts/UserModalContext/UserModalProvider"
import ListGroupStudentsAdmin from "../../../Components/SchoolAdminModalsComponents/ListData/ListGroupStudentsAdmin"
import ListGroupUsersAdmin from "../../../Components/SchoolAdminModalsComponents/ListData/ListGroupUsersAdmin"
import ListGroupStudents from "../../../Components/SharedComponents/ListData/ListGroupStudents"
import ListGroupUsers from "../../../Components/SharedComponents/ListData/ListGroupUsers"


function UserSeeGroupModal() {

	const { groupToSee } = useUserModalContext()
	const { user } = useGeneralContext()
	const isUserAdmin = user ? user.admin !== 'No' : false

	return (
		<>
			<h2>{groupToSee.name}</h2>
			{isUserAdmin ? (
				<>
					<ListGroupUsersAdmin/>
					<ListGroupStudentsAdmin/>
				</>
			) : (
				<>
					<ListGroupUsers/>
					<ListGroupStudents/>
				</>
			)}
		</>
	)
}

export default UserSeeGroupModal