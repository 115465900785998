import React, { useState } from "react"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider";
import { useSchoolAdminModalContext } from "../../../Contexts/SchoolAdminContext/SchoolAdminProvider";
import { GroupInterface, RoleInterface, StudentInterface, UserInterface } from "../../../Interfaces/Entities";
import SearchUserAdmin from "../../../Components/SchoolAdminModalsComponents/SearchData/SearchUserAdmin";
import SearchStudentAdmin from "../../../Components/SchoolAdminModalsComponents/SearchData/SearchStudentAdmin";
import ViewGroupUser from "../../../Components/SchoolAdminModalsComponents/ViewData/ViewGroupUser";
import { FetchPostFunction, FetchPutFunction } from "../../../Helpers/FetchHelpers";
import '../SchoolAdminCSS.css'



function SchoolAdminCreateGroupModal() {

	const { schoolOfUser, setCurrentModal } = useGeneralContext()
	const { schoolGroups, setSchoolGroups } = useSchoolAdminModalContext()
	const [newGroupName, setNewGroupName] = useState<string>('')
	const [groupUsers, setGroupUsers] = useState<string[]>([])
	const [groupStudents, setGroupStudents] = useState<StudentInterface[]>([])
	const [userPriorities, setUserPriorities] = useState<{ [userSK: string]: RoleInterface }>({})


	const handleChangeNameGroup = (e: React.ChangeEvent<HTMLInputElement>) => {
		const searchValue = e.target.value;
		setNewGroupName(searchValue);
	}

	const handleUserClick = (user: UserInterface) => {
		setGroupUsers([...groupUsers, user.SK])
		setUserPriorities({ ...userPriorities, [user.SK]: { 'General': 'mid' } })
	}

	const handleDeleteUser = (user: string) => {
		const updatedUsers = groupUsers.filter((iterationUser: string) => (
			iterationUser !== user
		))
		setGroupUsers(updatedUsers)
	}

	const handleStudentClick = (student: StudentInterface) => {
		if (!groupStudents.some(groupStudent => groupStudent.rut === student.rut)) {
			setGroupStudents([...groupStudents, student])
		}
	}

	const handleDeleteStudent = (student: StudentInterface) => {
		const updatedStudents = groupStudents.filter((iterationStudent: StudentInterface) => (
			iterationStudent.rut !== student.rut
		))
		setGroupStudents(updatedStudents)
	}

	const handleUserTemplateChange = async ( template : RoleInterface, user_id: string) : Promise<void> => {
		const updatedTemplate = { ...userPriorities, [user_id] : template}
		setUserPriorities(updatedTemplate)
	}

	const handleCreateGroup = async () => {
		if (!schoolOfUser) {
			return
		}
		try {
			const url = `${process.env.REACT_APP_BACKEND_URL}/groups`
			const body = {
				"school_id": schoolOfUser.SK,
				"students": groupStudents,
				"name": newGroupName
			}
			await FetchPostFunction(url, body)
			// NOTE: Change this SK generation if is changed in backend
			const groupSK = `${newGroupName}_${new Date().getFullYear()}`
			await handleUsersUpdate(groupSK)
			await updateInternalGroup(groupSK)
			setCurrentModal('AllGroupList')
			alert('El grupo fue correctamente creado')
		} catch (error) {
			alert('Hubo un problema al crear el grupo')
		}
	}

	const handleUsersUpdate = async (groupSk: string) => {
		if (!schoolOfUser) {
			return
		}
		for (let index = 0; index < groupUsers.length; index++) {
			const currentUserSK = groupUsers[index];
			const url = `${process.env.REACT_APP_BACKEND_URL}/user-groups/${currentUserSK}?school_id=${schoolOfUser.SK}`
			const body = {
				"groups": {[groupSk] : userPriorities[currentUserSK] ? userPriorities[currentUserSK] : {}},
				"to_delete": {}
			}
			await FetchPutFunction(url, body)
		}
	}

	const updateInternalGroup = (groupSk: string) => {
		const groupBeenCreated : GroupInterface = {
			SK : groupSk,
			PK : `${groupSk}#GROUPS`,
			name : newGroupName,
			students : groupStudents
		}
		const updatedGroups = [...schoolGroups, groupBeenCreated]
		setSchoolGroups(updatedGroups)
	}

	return (
		<>
			<div className="CreateGroupTitleDiv">
				<h4>Nombre grupo</h4>
				<input
					value={newGroupName}
					onChange={handleChangeNameGroup}
					placeholder="Ejemplo: Primer ciclo"
					className="NameGroupInput"
					autoComplete='createGroupName'
					name="groupName"
				/>
			</div>
			<div className="CreateGroupUserDiv">
				<h4>Usuarios</h4>
				<SearchUserAdmin
					groupUsers={groupUsers}
					handleSearchClick={handleUserClick}
				/>
				{groupUsers.length === 0 ? (
					<div className="ListUserEmptyList">
						<h4>Este grupo no tiene usuarios</h4>
					</div>

				) : (
					groupUsers.map((user: string) => (
						<div className="IndividualUserMainDiv">
							<ViewGroupUser
								user={user}
								currentPriorities={userPriorities[user] ? userPriorities[user] : {}}
								updateTemplateExternal={handleUserTemplateChange}
							/>
							<button type='button' onClick={() => handleDeleteUser(user)}>
								X
							</button>
						</div>
					))
				)}
			</div>
			<div className="CreateGroupStudentDiv">
				<h4>Estudiantes</h4>
				<SearchStudentAdmin
					groupStudents={groupStudents}
					handleSearchClick={handleStudentClick}
				/>
				{groupStudents.length === 0 ? (
					<div className="ListUserEmptyList">
						<h4>Este grupo no tiene estudiantes</h4>
					</div>
				) : (
					<div>
						{groupStudents.map((student: StudentInterface, index: number) => (
							<div className="IndividualStudentDiv">
								<h4 className="IndexColumn">{index}</h4>
								<h4 className="NameColumn">{student.name}</h4>
								<button type='button' onClick={() =>
									handleDeleteStudent(student)}>
									{/* TODO: Replace X with trash icon*/}
									X
								</button>
							</div>
						))}
					</div>
				)}
			</div>
			<button type='button' className="CreateGroupButton" onClick={() => handleCreateGroup()}>
				Crear grupo
			</button>
		</>
	)
}

export default SchoolAdminCreateGroupModal