import { useEffect, useState } from "react"
import { useUserModalContext } from "../../../Contexts/UserModalContext/UserModalProvider"
import { NoticeInterface } from "../../../Interfaces/Entities"
import ListGroupOfNotices from "../ListData/ListGroupOfNotices"
import { FilterPreviusNotices, FilterThisWeekNotices, FilterTodaysNotices } from "../../../Helpers/FilterFuntions"


function GroupHistoryLetters() {

	const { savedNewsLetters } = useUserModalContext()
	const [todayNewsLetters, setTodayNewsLetters] = useState<NoticeInterface[]>([])
	const [thisWeekNewsLetters, setThisWeekNewsLetters] = useState<NoticeInterface[]>([])
	const [previusNewsLetters, setPreviusNewsLetters] = useState<NoticeInterface[]>([])

	const distributeNewsLetters = (notices : NoticeInterface[]) => {
		setTodayNewsLetters(FilterTodaysNotices(notices))
		setThisWeekNewsLetters(FilterThisWeekNotices(notices))
		setPreviusNewsLetters(FilterPreviusNotices(notices))
	}

	useEffect(() => {
		distributeNewsLetters(savedNewsLetters)
	}, [savedNewsLetters])


	return (
		<>
			<ListGroupOfNotices
				noticesList={todayNewsLetters}
				listTitle="Boletines de hoy"
				emptyListMessage="No hay boletines de hoy"
			/>
			<ListGroupOfNotices
				noticesList={thisWeekNewsLetters}
				listTitle="Boletines de esta semana"
				emptyListMessage="No hay boletines de esta semana"
			/>
			<ListGroupOfNotices
				noticesList={previusNewsLetters}
				listTitle="Boletines anteriores"
				emptyListMessage="No hay boletines anteriores"
				isLast={true}
			/>
		</>
	)
}

export default GroupHistoryLetters