import AdminModalProvider from "../../Contexts/AdminModalContext/AdminModalProvider";
import AdminModalSelecter from "../../Modals/AdminModals/AdminModalSelecter";
import AdminModalContainer from "../../Modals/AdminModals/AdminModalContainer";
import UserModalProvider from "../../Contexts/UserModalContext/UserModalProvider";
import SchoolAdminModalProvider from "../../Contexts/SchoolAdminContext/SchoolAdminProvider";
import '../../index.css'

function AdministratorPage() {
	return (
		<div className="PageMainDiv">
			<AdminModalProvider>
				<SchoolAdminModalProvider>
					<UserModalProvider>
						<div className="PageLeftSide">
							<AdminModalSelecter />
						</div>
						<div className="PageRightSide">
							<AdminModalContainer />
						</div>
					</UserModalProvider>
				</SchoolAdminModalProvider>
			</AdminModalProvider>
		</div>
	)
}


export default AdministratorPage;