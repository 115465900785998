import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import "../../../Modals/AdminModals/AdminCSS.css"

function CreateSchoolButton() {

	const { setCurrentModal } = useGeneralContext()

	const handleOnClick = () => {
		setCurrentModal('SchoolCreater')
	}

	return (
		<div className="CreateSchoolBox" onClick={handleOnClick}>
			<h2>(+)</h2>
			<h2>Crear nueva escuela</h2>
		</div>
	)
}

export default CreateSchoolButton