import ViewCommentsOfNotice from "../../../Components/SharedComponents/ViewData/ViewCommentsOfNotice"
import ViewNoticeMainData from "../../../Components/SharedComponents/ViewData/ViewNoticeMainData"
import '../UserModalCSS.css'


function UserSeeNoticeModal() {

	return (
		<>
			<ViewNoticeMainData/>
			<ViewCommentsOfNotice/>
		</>
	)
}

export default UserSeeNoticeModal