import { useState } from "react"
import { ViewGroupUserInterface } from "../../../Interfaces/ComponentsInterfaces/SchoolAdminInterfaces"
import { RoleInterface } from "../../../Interfaces/Entities"
import { TranslateUserName } from "../../../Helpers/TranslateHelpers"
import '../SchoolAdmin.css'
import MapUserPriorities from "../MapData/MapUserPriorities"


function ViewGroupUser({ user, currentPriorities, updateTemplateExternal }: ViewGroupUserInterface) {

	const [isBoxOpen, setIsBoxOpen] = useState<boolean>(false)

	const updateTemplate = async (updatedTemplate: RoleInterface) => {
		if (updateTemplateExternal) {
			await updateTemplateExternal(updatedTemplate, user)
		}
	};

	return (
		<div className="IndividualUserInternalMainSubDiv">
			<div className={`IndividualUserInternalDiv`}>
				<h3>{TranslateUserName(user)}</h3>
				<button type='button' onClick={() => setIsBoxOpen(!isBoxOpen)}>
					{isBoxOpen ? 'Cerrar' : 'Ver prioridades'}
				</button>
			</div>
			{isBoxOpen && (
				<MapUserPriorities 
					currentPriorities={currentPriorities}
					updateTemplate={updateTemplate}
				/>
			)}
		</div>
	)
}

export default ViewGroupUser