import React, { useState } from "react"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import { useSchoolAdminModalContext } from "../../../Contexts/SchoolAdminContext/SchoolAdminProvider"
import { useUserModalContext } from "../../../Contexts/UserModalContext/UserModalProvider"
import { MapGroupInterface } from "../../../Interfaces/ComponentsInterfaces/SchoolAdminInterfaces"
import { GroupOfUserInterface } from "../../../Interfaces/Entities"
import ConfirmationPopup from "../../SharedComponents/OtherComponents/ConfirmationPopUp"
import { FetchDeleteFunction, FetchPutFunction } from "../../../Helpers/FetchHelpers"
import TrashBinIcon from '../../../Assets/TrashBinIcon.png'
import EditIcon from '../../../Assets/EditIcon.png'
import ConfirmIcon from '../../../Assets/CheckIcon.png'
import CancelIcon from '../../../Assets/CancelIcon.png'
import '../SchoolAdmin.css'


function MapGroup({ groupToShow, index }: MapGroupInterface) {

	const { schoolOfUser, setCurrentModal, groupsOfUser, setGroupsOfUser } = useGeneralContext()
	const { setGroupToSee } = useUserModalContext()
	const { schoolGroups, setSchoolGroups, savedGroups, getInfoOfSingularGroup } = useSchoolAdminModalContext()
	const [confirmationPopUp, setConfirmationPopUp] = useState<boolean>(false)
	const [isEdditingName, setIsEdditingName] = useState<boolean>(false)
	const [editName, setEditName] = useState<string>(groupToShow.name)

	const deleteUserPriority = async (userList: string[]) => {
		if (!schoolOfUser) {
			return
		}
		for (const userSK of userList) {
			const url = `${process.env.REACT_APP_BACKEND_URL}/user-groups/${userSK}?school_id=${schoolOfUser.SK}`;
			const body = {
				"groups": {},
				"to_delete": { [groupToShow.SK]: {} }
			};
			await FetchPutFunction(url, body)
		}
	};
	
	const handleDeleteUserPriority = async () => {
		const groupUsers = await getGroupUsers();
		await deleteUserPriority(groupUsers);
	};
	
	const getGroupUsers = async (): Promise<string[]> => {
		const groupPriorities = savedGroups.includes(groupToShow.SK)
			? groupToShow.users_priorities
			: (await getInfoOfSingularGroup(groupToShow.SK)).users_priorities;
	
		return Object.keys(groupPriorities || {});
	};
	
	
	const deleteGroup = async () => {
		if (!schoolOfUser) {
			return
		}
		await handleDeleteUserPriority();
		try {
			const url = `${process.env.REACT_APP_BACKEND_URL}/groups/${groupToShow.SK}?school_id=${schoolOfUser.SK}`;
			await FetchDeleteFunction(url)
			updateGroupsForContext();
			alert("Grupo correctamente eliminado");
			setConfirmationPopUp(false);
		} catch (error) {
			alert('Hubo un problema al eliminar el grupo');
		}
	};
	
	const updateGroupsForContext = () => {
		const updatedGroups = schoolGroups.filter(group => group.SK !== groupToShow.SK);
		setSchoolGroups(updatedGroups);
	
		const updatedGroupsOfUser = groupsOfUser.filter(
			(groupToFilter: GroupOfUserInterface) => groupToFilter.group_id !== groupToShow.SK
		);
		setGroupsOfUser(updatedGroupsOfUser);
	};
	
	const handleViewButtonClick = () => {
		setGroupToSee(groupToShow);
		setCurrentModal('SeeGroup');
	};
	
	const handleCancelEdit = () => {
		setIsEdditingName(false);
		setEditName(groupToShow.name);
	};
	
	const handleEditConfirm = async () => {
		try {
			await updateGroupName();
			updateInternalGroups();
			alert("Grupo correctamente actualizado");
			setIsEdditingName(false);
		} catch (error) {
			alert("Hubo un problema al actualizar el nombre");
			handleCancelEdit();
		}
	};
	
	const updateGroupName = async () => {
		if (!schoolOfUser) {
			return
		}
		const url = `${process.env.REACT_APP_BACKEND_URL}/groups/${groupToShow.SK}`;
		const body = {
			'attrs': { 'name': editName },
			'school_id': schoolOfUser.SK
		};
		await FetchPutFunction(url, body)
	};
	
	const updateInternalGroups = () => {
		const updatedGroups = schoolGroups.map((group) =>
			group.SK === groupToShow.SK ? { ...group, name: editName || group.name } : group
		);
		setSchoolGroups(updatedGroups);
	
		const updatedUserGroups = groupsOfUser.map((groupOfUser: GroupOfUserInterface) => (
			groupOfUser.group_id === groupToShow.SK ? { ...groupOfUser, label: editName || groupOfUser.label } : groupOfUser
		));
		setGroupsOfUser(updatedUserGroups);
	};
	
	const handleEdditNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setEditName(e.target.value);
	};
	

	return (
		<tr>
			<td>{index}</td>
			<td>
				{isEdditingName ? (
					<input
						value={editName}
						onChange={handleEdditNameChange}
						placeholder="Nuevo Nombre..."
						className="EditGroupNameInput"
					/>
				) : (
					groupToShow.name
				)}
			</td>
			<td>
				<button type='button' onClick={handleViewButtonClick}>
					Ver
				</button>
			</td>
			<td className={isEdditingName ? 'ExtraButtonsGroup' : ''}>
				{isEdditingName ? (
					<>
						<button type='button' onClick={handleEditConfirm}>
							<img src={ConfirmIcon} alt="ConfirmIcon"/>
						</button>
						<button type='button' onClick={handleCancelEdit}>
							<img src={CancelIcon} alt="CancelIcon"/>
						</button>
					</>
				) : (
					<>
						<button onClick={() => setIsEdditingName(true)}>
							<img src={EditIcon} alt="EditIcon"/>
						</button>
					</>
				)}

			</td>
			<td>
				<button onClick={() => setConfirmationPopUp(true)}>
					<img src={TrashBinIcon} alt="DeleteIcon"/>
				</button>
			</td>
			{
				confirmationPopUp && (
					<ConfirmationPopup
						valueToConfirmate={groupToShow.name}
						deletionType="group"
						deleteFunction={deleteGroup}
						setIsPopUpVisible={setConfirmationPopUp}
					/>
				)
			}
		</tr >
	)
}

export default MapGroup