import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import '../SchoolAdmin.css'

function ViewSchoolAdminExtraButtons() {

	const { setCurrentModal } = useGeneralContext()

	return (
		<div className="ExtraButtonsMainDiv">
			<div onClick={() => setCurrentModal('EmergencyTypes')} className="ExtraButtonsSingularDiv">
				<h2>Situaciones críticas</h2>
			</div>
			<div onClick={() => setCurrentModal('AllGroupList')} className="ExtraButtonsSingularDiv">
				<h2>Grupos</h2>
			</div>
			<div onClick={() => setCurrentModal('StudentsAndUsers')} className="ExtraButtonsSingularDiv">
				<h2>Usuarios y Alumnos</h2>
			</div>
		</div>
	)
}

export default ViewSchoolAdminExtraButtons