import { GroupInterface, NoticeInterface, StudentInterface, UserInterface } from "../Interfaces/Entities";
import { AreTwoDatesTheSameDay, CreateDateFromNoticeDate, isDateInThisWeek } from "./DateHelpers";

export function FilterGroupsForSearch(allGroups : GroupInterface[], searchValue: string, 
	currentGroups: GroupInterface[]) : GroupInterface[] {
	const filtered = allGroups.filter((group: GroupInterface) => {
		const searchLower = searchValue.toLowerCase();
		const nameMatches = group.name.toLowerCase().includes(searchLower);
		const SKMatches = group.SK.toLowerCase().includes(searchLower);

		const isInGroup = currentGroups.some(
			(iterationGroup: GroupInterface) => iterationGroup.SK === group.SK
		);
		return (nameMatches || SKMatches) && !isInGroup;
	});
	return filtered
}

export function  FilterStudentsForSearch(allStudents : StudentInterface[], searchValue: string, 
	currentStudents: StudentInterface[]) : StudentInterface[] {
	const filtered = allStudents.filter((student: StudentInterface) => {
		const searchLower = searchValue.toLowerCase();
		const nameMatches = student.name.toLowerCase().includes(searchLower);
		const rutMatches = student.rut.toLowerCase().includes(searchLower);

		const isInGroup = currentStudents.some(
			(groupStudent: StudentInterface) => groupStudent.rut === student.rut
		);
		return (nameMatches || rutMatches) && !isInGroup;
	});
	return filtered
}

export function FilterUserForSearch(allUsers : UserInterface[], searchValue: string, 
	currentUsers: string[]): UserInterface[] {
	const filtered = allUsers.filter((user: UserInterface) => {
		const searchLower = searchValue.toLowerCase();
		const nameMatches = user.name.toLowerCase().includes(searchLower);
		const skMatches = user.SK.toLowerCase().includes(searchLower);
		const mailMatches = user.email.toLowerCase().includes(searchLower)

		const isInGroup = currentUsers.some((user_id: string) => user_id === user.SK);
		return (nameMatches || skMatches || mailMatches) && !isInGroup;
	});
	return filtered
}

export function FilterTodaysNotices(noticesToFilter : NoticeInterface[]): NoticeInterface[] {
	return noticesToFilter.filter((notice: NoticeInterface) => AreTwoDatesTheSameDay(new Date(), 
		CreateDateFromNoticeDate(notice.date)))
}

export function FilterThisWeekNotices(noticesToFilter: NoticeInterface[]): NoticeInterface[] {
	return noticesToFilter.filter(notice => isDateInThisWeek(notice.date) );
}

export function FilterPreviusNotices(noticesToFilter: NoticeInterface[]): NoticeInterface[] {
	const todayNotices = FilterTodaysNotices(noticesToFilter)
	const thisWeekNotices = FilterThisWeekNotices(noticesToFilter)
	return noticesToFilter.filter((notice: NoticeInterface) =>
		!todayNotices.some((todayNotice: NoticeInterface) => todayNotice.SK === notice.SK) &&
		!thisWeekNotices.some((thisWeekNotice: NoticeInterface) => thisWeekNotice.SK === notice.SK)
	)
}

export function FilterUniqueStudents(allGroups : GroupInterface[]) : StudentInterface[] {
	const myStudents = Array.from(
		allGroups.reduce((acc, course) => {
			course.students.forEach((student : StudentInterface) => {
				acc.set(student.rut, student);
			});
			return acc;
		}, new Map()).values()
	);
	return myStudents
}