import React, { useState } from "react"
import { useSchoolAdminModalContext } from "../../../Contexts/SchoolAdminContext/SchoolAdminProvider";
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider";
import { GroupInterface, StudentInterface } from "../../../Interfaces/Entities";
import SearchGroupAdmin from "../../../Components/SchoolAdminModalsComponents/SearchData/SearchGroupAdmin";
import { FetchPutFunction } from "../../../Helpers/FetchHelpers";
import '../SchoolAdminCSS.css'


function SchoolAdminCreateStudentModal() {

	const { schoolOfUser, setCurrentModal } = useGeneralContext()
	const { schoolGroups, setSchoolGroups, schoolStudents, setSchoolStudents } = useSchoolAdminModalContext()
	const [studentName, setStudentName] = useState<string>('')
	const [studentGroups, setStudentsGroups] = useState<GroupInterface[]>([])

	const handleEdditNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const searchValue = e.target.value;
		setStudentName(searchValue);
	};

	const handleStudentCreation = async () => {
		if (!schoolOfUser) {
			return
		}
		if (studentName.trim() === '') {
			alert('El nombre no puede estar vacio')
			return
		}
		if (studentGroups.length === 0) {
			alert('El estudiante debe estar en al menos 1 grupo')
			return
		}
		try {
			const newStudent: StudentInterface = { name: studentName, rut: studentName }
			for (let index = 0; index < studentGroups.length; index++) {
				const currentGroup = studentGroups[index];
				const updatedStudents = [...currentGroup.students, newStudent]
				const url = `${process.env.REACT_APP_BACKEND_URL}/groups/${currentGroup.SK}`
				const body = {
					'attrs': {
						'students': updatedStudents
					},
					'school_id': schoolOfUser.SK
				}
				await FetchPutFunction(url, body)
			}
			updateInternalGroups(newStudent)
			setCurrentModal('StudentsAndUsers')
			alert("Estudiante correctamente creado")
		} catch (error) {
			alert("Hubo un problema al crear el estudiante")
		}
	}

	const updateInternalGroups = (newStudent: StudentInterface) => {
		const updatedGroups = schoolGroups.map(group => {
			const isGroupInStudentsGroups = studentGroups.some(studentGroup => studentGroup.PK === group.PK);
			if (isGroupInStudentsGroups) {
				const isStudentInGroup = group.students.some(student => student.rut === newStudent.rut);
				if (!isStudentInGroup) {
					return {...group, students: [...group.students, newStudent],};
				}
			}
			return group;
		});
		setSchoolGroups(updatedGroups)
		const updatedStudents = [...schoolStudents, newStudent]
		setSchoolStudents(updatedStudents)
	}

	const handleSearchClick = (group: GroupInterface) => {
		if (!studentGroups.includes(group)) {
			const updatedGroups = [...studentGroups, group]
			setStudentsGroups(updatedGroups)
		}
	}

	const handleGroupDelete = (groupToDelete: GroupInterface) => {
		const updatedGroups = studentGroups.filter((group: GroupInterface) => (
			group.SK !== groupToDelete.SK
		))
		setStudentsGroups(updatedGroups)
	}

	return (
		<div className="CreateStudentMainDiv">
			<div className="CreateStudentTopDiv">
				<h4>Nombre y apellidos</h4>
				<input
					value={studentName}
					onChange={handleEdditNameChange}
					placeholder="Ejemplo: Juan Pablo Araya Ramírez"
					className="CreateStudentNameInput"
				/>
			</div>
			<div className="CreateStudentGroupDiv">
				<h4>Grupos del estudiante</h4>
				<SearchGroupAdmin
					currentGroups={studentGroups}
					handleSearchClick={handleSearchClick}
				/>
				<div className="CreateStudentGroupInternalDiv">
					{studentGroups.length === 0 ? (
						<h4>El estudiante no tiene grupos, debe ingresarlo a al menos un grupo para crearlo</h4>
					) : (
						<div className="CreateStudentMapGroupDiv">
							{studentGroups.map((group: GroupInterface) => (
								<button onClick={() => handleGroupDelete(group)}>
									{group.name}
								</button>
							))}
						</div>
					)}

				</div>
			</div>
			<div className="CreateStudentButtonDiv">
				<button type='button' onClick={handleStudentCreation}>
					Crear estudiante
				</button>
			</div>
		</div>
	)
}

export default SchoolAdminCreateStudentModal