import OneSignal from 'react-onesignal';

export async function OneSignalInit(userSK: string) {
	const appId = process.env.REACT_APP_ONE_SIGNAL_APP_ID || ''
	const isLocal = process.env.REACT_APP_ENVIOREMENT === "Local"
	if (isLocal) {
		console.log("Local")
		return
	}
	try {
		await OneSignal.init({
			appId: appId,
			allowLocalhostAsSecureOrigin: true, 
		});
		await OneSignal.login(userSK)
		await OneSignal.User.PushSubscription.optIn()
	} catch (error) {
		await OneSignal.login(userSK)
		await OneSignal.User.PushSubscription.optIn()
		console.error("Error loginOut OneSignal:", error)
	}
}

export async function OneSignalLogOut() {
	const isLocal = process.env.REACT_APP_ENVIOREMENT === 'Local'
	if (isLocal) {
		return
	}
	try {
		if (!OneSignal.Session) {
			return
		}
		await OneSignal.User.PushSubscription.optOut()
		await OneSignal.logout()
	} catch (error) {
		console.error("Error loginOut OneSignal:", error)
	}
}
