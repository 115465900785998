import { useEffect, useState } from "react";
import { ListGroupStudentsInterface } from "../../../Interfaces/ComponentsInterfaces/SharedInterfaces";
import { StudentInterface } from "../../../Interfaces/Entities";
import "../SharedComponents.css"
import { useUserModalContext } from "../../../Contexts/UserModalContext/UserModalProvider";


function ListGroupStudents({  }: ListGroupStudentsInterface) {

	const {groupToSee} = useUserModalContext()
	const [groupStudents, setGroupStudents] = useState<StudentInterface[]>(groupToSee.students)

	useEffect(() => {
		setGroupStudents(groupToSee.students)
	}, [groupToSee])

	return (
		<div className="ListGroupStudentMainDiv">
			<h4>Estudiantes</h4>
			{groupStudents.length === 0 ? (
				<div className="ListUserEmptyList">
					<h4>Este grupo no tiene estudiantes</h4>
				</div>
			) : (
				groupStudents.map((student: StudentInterface, index: number) => (
					<div className="IndividualStudentDiv">
						<h4 className="IndexColumn">{index}</h4>
						<h4 className="NameColumn">{student.name}</h4>
					</div>
				))
			)}
		</div>
	)
}

export default ListGroupStudents