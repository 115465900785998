import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { confirmResetPassword, resetPassword } from "aws-amplify/auth"
import { LostPasswordInterface } from "../../Interfaces/ComponentsInterfaces/LoginPageInterface"
import LinkClassLogo from '../../Assets/LinkClassLogo.png'
import '../../Views/LoginPage/LoginPage.css'


function LostPasswordModal({ setLoginModal }: LostPasswordInterface) {

	const navigate = useNavigate()

	const [username, setUsername] = useState<string>('')
	const [code, setCode] = useState<string>('')
	const [newPassword, setNewPassword] = useState<string>('')
	const [resetStage, serResetStage] = useState<number>(1)
	const [isRevealingPassword, setIsRevealingPassword] = useState<boolean>(false)

	const handleForgotPassword = async () => {
		try {
			await resetPassword({ username: username })
			alert('Código de verificación enviado a tu correo electrónico o teléfono.');
			serResetStage(2);
		} catch (error) {
			alert('Error al enviar el código de verificación');
		}
	};

	const handleForgotPasswordSubmit = async () => {
		try {
			await confirmResetPassword({ username: username, confirmationCode: code, newPassword: newPassword });
			alert('Contraseña cambiada exitosamente. Ahora puedes iniciar sesión con tu nueva contraseña.');
			setLoginModal('Login')
		} catch (error) {
			alert('Error al cambiar la contraseña');
		}
	};

	const handleReturnToLanding = () => {
		navigate('/')
	}


	return (
		<div className="InternalDiv">
			<div className="LoginLogoDiv" onClick={handleReturnToLanding}>
				<img src={LinkClassLogo} alt="LinkClassLogo" />
			</div>
			<h1>Recuperar Contraseña</h1>
			{resetStage === 1 ? (
				<>
					<div className="LoginInputDiv">
						<input
							type='text'
							placeholder="Correo..."
							value={username}
							onChange={(e) => setUsername(e.target.value)}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									e.preventDefault();
								}
							}}
						/>
					</div>
					<div className="LostPasswordButtonDiv">
						<button
							type="button"
							onClick={() => setLoginModal('Login')}
						>
							Cancelar
						</button>
						<button
							type='button'
							onClick={handleForgotPassword}
						>
							Enviar correo
						</button>
					</div>
				</>
			) : (
				<>
					<div className="LoginInputDiv">
						<input
							type='text'
							placeholder="Código de Verificación..."
							value={code}
							onChange={(e) => setCode(e.target.value)}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									e.preventDefault();
								}
							}}
						/>
					</div>
					<div className="LoginPassWordInput">
						<input
							name="newPassword"
							id="newPaswordInput"
							autoComplete='new-password-input'
							type={isRevealingPassword ? "text" : "password"}
							value={newPassword}
							placeholder="Contraseña"
							onChange={(e) => setNewPassword(e.target.value)}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									e.preventDefault();
								}
							}}
						/>
						<button type="button" onClick={() => setIsRevealingPassword(!isRevealingPassword)}>
							{isRevealingPassword ? "Ocultar Contraseña" : "Mostrar contraseña"}
						</button>
					</div>
					<div className="LostPasswordButtonDiv">
						<button
							type="button"
							onClick={() => setLoginModal('Login')}
						>
							Cancelar
						</button>
						<button
							type='button'
							onClick={handleForgotPasswordSubmit}
						>
							Confirmar Nueva Contraseña
						</button>
					</div>
				</>
			)}

		</div>
	)
}

export default LostPasswordModal