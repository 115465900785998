import { useEffect } from "react"
import { Route, Routes, useLocation } from "react-router-dom"
import LandingPage from "./Views/LandingPage/LandingPage"
import AdministratorPage from "./Views/AdministratorPage/AdministratorPage"
import SchoolAdministratorPage from "./Views/SchoolAdministratorPage/SchoolAdministratorPage"
import UserPage from "./Views/UserPage/UserPage"
import LoginPage from "./Views/LoginPage/LoginPage"
import "./index.css"


function Routing() {

	const location = useLocation();

	useEffect(() => {
		sessionStorage.setItem('lastLocation', location.pathname);
	}, [location.pathname]);

	return (
		<>
			<div className="PagesContainers">
				<Routes>
					<Route path="/*" element={<LandingPage />} />
					<Route path="/admin" element={<AdministratorPage />} />
					<Route path="/admin/colegio/:schoolSK" element={<SchoolAdministratorPage />} />
					<Route path="/colegio/:schoolSK" element={<UserPage />} />
					<Route path="/login" element={<LoginPage />} />
				</Routes>
			</div>
		</>
	)
}

export default Routing;
