import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider";
import { useSchoolAdminModalContext } from "../../../Contexts/SchoolAdminContext/SchoolAdminProvider";
import { MapStudentInterface } from "../../../Interfaces/ComponentsInterfaces/SchoolAdminInterfaces";

function MapStudent({ student, index }: MapStudentInterface) {

	const { setCurrentModal } = useGeneralContext()
	const { setStudentToEdit } = useSchoolAdminModalContext()

	const handleViewStudent = () => {
		setStudentToEdit(student)
		setCurrentModal('EditStudent')
	}

	return (
		<tr>
			<td>{index}</td>
			<td>{student.name}</td>
			<td>
				<button type='button' onClick={handleViewStudent}>
					Ver
				</button>
			</td>
		</tr>
	)
}

export default MapStudent