import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import LoginModal from "../../Modals/LoginModals/LoginModal"
import LostPasswordModal from "../../Modals/LoginModals/LostPasswordModal";
import ConfirmPasswordModal from "../../Modals/LoginModals/ConfirmPasswordModal";
import './LoginPage.css'


function LoginPage() {

	const navigate = useNavigate()

	const [loginModal, setLoginModal] = useState<"Login" | "LostPassword" | 'ConfimPassword'>('Login')
	const [userName, setUserName] = useState<string>('')

	useEffect(() => {
		const privateLocation = sessionStorage.getItem('lastLocation')
		if (privateLocation !== '/' && privateLocation !== '/login') {
			navigate('/')
		}
	}, [])

	return (
		<div className="MainModalDiv">
			<div className="SwitchDiv">
				{(() => {
					switch (loginModal) {
						case 'Login':
							return <LoginModal
								setLoginModal={setLoginModal}
								setLoginUsername={setUserName}
							/>;
						case 'LostPassword':
							return <LostPasswordModal
								setLoginModal={setLoginModal}
							/>;
						case 'ConfimPassword':
							return <ConfirmPasswordModal
								setLoginModal={setLoginModal}
								loginUserName={userName}
							/>
						default:
							return <></>;
					}
				})()}
			</div>
		</div>
	)



}

export default LoginPage