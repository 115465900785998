import { SchedualInterface } from "../Interfaces/Entities";

export function PrepareFileToSend(file: File): Promise<{ name: string; data: string; content_type: string }> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onloadend = () => {
			const result = reader.result as string;
			const base64Data = result.split(',')[1];
			resolve({
				name: file.name,
				data: base64Data,
				content_type: file.type,
			});
		};

		reader.onerror = () => {
			reject(new Error("Error reading file"));
		};

		reader.readAsDataURL(file);
	});
}


export function PrepareScheduleForUser(userSchedule: SchedualInterface): SchedualInterface {
	const dayOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday"]
	const scheduleToReturn: SchedualInterface = {}
	const keys = Object.keys(userSchedule)

	for (let index = 0; index < dayOfWeek.length; index++) {
		const day = dayOfWeek[index];
		if (keys.includes(day)) {
			scheduleToReturn[day] = userSchedule[day]
		} else {
			scheduleToReturn[day] = "08:30-18:30"
		}
	}

	return scheduleToReturn
}