import SchoolAdminModalProvider from "../../Contexts/SchoolAdminContext/SchoolAdminProvider";
import UserModalProvider from "../../Contexts/UserModalContext/UserModalProvider";
import SchoolAdminModalSelecter from "../../Modals/SchoolAdminModals/SchoolAdminModalSelecter";
import SchoolAdminModalContainer from "../../Modals/SchoolAdminModals/SchoolAdminModalContainer";
import '../../index.css'



function SchoolAdministratorPage() {


	return (
		<div className="PageMainDiv">
			<UserModalProvider>
				<SchoolAdminModalProvider>
					<div className="PageLeftSide">
						<SchoolAdminModalSelecter />
					</div>
					<div className="PageRightSide">
						<SchoolAdminModalContainer />
					</div>
				</SchoolAdminModalProvider>
			</UserModalProvider>
		</div>
	)
}

export default SchoolAdministratorPage;