import { ReactNode, useContext, useEffect, useState } from "react";
import { fetchAuthSession, JWT } from "aws-amplify/auth";
import GeneralContext from "./GeneralContext";
import { GroupInterface, GroupOfUserInterface, SchoolInterface, UserInterface } from "../../Interfaces/Entities";
import { ModalTypes } from "../../Interfaces/ContextInterfaces/GeneralContext";
import { GetDayFromNow } from "../../Helpers/DateHelpers";
import { FetchGetFunction, FetchPutFunction } from "../../Helpers/FetchHelpers";
import { OneSignalInit } from "../../Helpers/OneSignalHelpers";
import { GetRedirectURL } from "../../Helpers/TranslateHelpers";
import { useNavigate } from "react-router-dom";


const GeneralProvider = ({ children }: { children: ReactNode }) => {

	const [user, setUser] = useState<UserInterface | undefined>(undefined)
	const [userToken, setUserToken] = useState<JWT | undefined>(undefined)
	const [hasLogged, setHasLogged] = useState<boolean>(false)
	const [groupsOfUser, setGroupsOfUser] = useState<GroupOfUserInterface[]>([])
	const [groupsOfUserAsGroups, setGroupsOfUserAsGroups] = useState<GroupInterface[]>([])
	const [hasLoadedGroups, setHasLoadedGroups] = useState<boolean>(false)
	const [schoolOfUser, setSchoolOfUser] = useState<SchoolInterface | undefined>(undefined)
	const [currentModal, setCurrentModal] = useState<ModalTypes['type']>('HomeModal');

	const navigate = useNavigate()


	const fetchAndSetData = async (url: string, setter: (data: any) => void) => {
		try {
			const dataToSet = await FetchGetFunction(url)
			if(!dataToSet) {
				return
			}
			setter(dataToSet);
		} catch (error) {}
	};

	const updateGroupsOfUser = async (schoolId: string) => {
		if (!user) {
			return
		}
		try {
			const url = `${process.env.REACT_APP_BACKEND_URL}/user-groups/${user.SK}?school_id=${schoolId}`;
			const groupOfUsersFromBackend = await FetchGetFunction(url)
			setGroupsOfUser(groupOfUsersFromBackend);
			await getInfoOfGroups(groupOfUsersFromBackend, schoolId)
		} catch (error) {}
	};

	const getInfoOfSchool = async (schoolID : string) => {
		const schoolUrl = `${process.env.REACT_APP_BACKEND_URL}/schools/${schoolID}`;
		await fetchAndSetData(schoolUrl, setSchoolOfUser);
	}

	const getInfoOfGroups = async (groupsOfUser: GroupOfUserInterface[], schoolId: string) => {
		const currentGroupList = await Promise.all(groupsOfUser.map(async (group) => {
			const url = `${process.env.REACT_APP_BACKEND_URL}/groups/${group.group_id}?school_id=${schoolId}`;
			const groupData = await FetchGetFunction(url);
			if (!groupData.hasOwnProperty('students')) {
				groupData.students = [];
			}
			return groupData;
		}));
		setGroupsOfUserAsGroups(currentGroupList);
	};

	const getInfoOfUser = async (username: string): Promise<{admin: string, school: string, user : UserInterface}> => {
		try {
			const url = `${process.env.REACT_APP_BACKEND_URL}/user-school/${username}`;
			const response = await FetchGetFunction(url)
			const userTranslated: UserInterface = {
				name: response.name,
				position: response.position,
				email: response.email,
				SK: response.SK,
				notification_token: response.notification_token,
				off_mode_until: response.off_mode_until,
				defaults: response.defaults,
				schedule: response.schedule,
				profile_image: response.profile_image || '',
				admin: response.admin || 'No',
				school: response.PK.split("#")[0]
			};
			if (response.admin && response.admin === 'SuperAdmin') {
				setCurrentModal('SchoolList');
			}
			if (userTranslated) {
				console.log(userTranslated)
				setUser(userTranslated);
			}
			return { admin: response.admin || 'No', school: response.PK.split("#")[0], user: userTranslated};
		} catch (error) {
			return { admin: 'No', school: '', user: {} as UserInterface};
		}
	};

	const turnOffSleepMode = async () => {
		const formattedDate = GetDayFromNow(-1);
		await sendDateToBackEnd(formattedDate);
	};

	const sendDateToBackEnd = async (formattedDate: string) => {
		if (!user || !schoolOfUser) {
			return
		}
		try {
			const url = `${process.env.REACT_APP_BACKEND_URL}/users/${user.SK}`;
			const body = {
				attrs: {
					off_mode_until: formattedDate,
				},
				school_id: schoolOfUser.SK,
			};
			await FetchPutFunction(url, body)
			const newUser = { ...user, off_mode_until: formattedDate };
			setUser(newUser);
		} catch (error) {}
	};

	const handleLogin = async (username : string, isNormalLogin: boolean) => {
		const session = await fetchAuthSession()
		const userData = await getInfoOfUser(username)
		setUserToken(session?.tokens?.accessToken)
		sessionStorage.setItem('userName', username)
		setHasLogged(true)
		console.log("Pre OS")
		await OneSignalInit(username)
		if (isNormalLogin) {
			alert("Login exitoso")
		}
		const redirectURL = GetRedirectURL(userData.admin, userData.school)
		navigate(redirectURL)
	}

	const handleUseEffect = async () => {
		if (!hasLogged) {
			const userName = sessionStorage.getItem('userName')
			if (userName) {
				await handleLogin(userName, false)
			}
		}
	}

	useEffect(() => {
		handleUseEffect()
	}, [])
	
	return (
		<GeneralContext.Provider value={{
			currentModal, setCurrentModal, user, setUser, userToken, setUserToken, hasLogged, setHasLogged, groupsOfUser, 
			setGroupsOfUser, groupsOfUserAsGroups, setGroupsOfUserAsGroups, hasLoadedGroups, setHasLoadedGroups, 
			schoolOfUser, setSchoolOfUser, updateGroupsOfUser, turnOffSleepMode, sendDateToBackEnd, getInfoOfUser, 
			getInfoOfSchool, handleLogin
		}}>
			{children}
		</GeneralContext.Provider>
	)
}

function useGeneralContext() {
	const context = useContext(GeneralContext);
	if (!context) {
		throw new Error('useModal must be used within a ModalProvider');
	}
	return context;
}

export { useGeneralContext }
export default GeneralProvider;