import { ReactNode, useContext, useEffect, useState } from "react";
import AdminModalContext from "./AdminModalContext";
import { SchoolInterface } from "../../Interfaces/Entities";
import { FetchGetFunction } from "../../Helpers/FetchHelpers";



function AdminModalProvider({ children }: { children: ReactNode }) {

	const [adminSchools, setAdminSchools] = useState<SchoolInterface[]>([]);
	const [isLoadingSchools, setIsLoadingSchools] = useState<boolean>(false)
	const [hasSelectedSchool, setHasSelectedSchool] = useState<boolean>(false)

	const getSchoolsFromBackend = async () => {
		try {
			setIsLoadingSchools(true)
			const url = `${process.env.REACT_APP_BACKEND_URL}/schools`
			const schoolsFromBackend = await FetchGetFunction(url)
			setAdminSchools(schoolsFromBackend)
			setIsLoadingSchools(false)
		} catch (error) { }
	}

	useEffect(() => {
		getSchoolsFromBackend()
	}, [])

	return (
		<AdminModalContext.Provider value={{
			adminSchools, setAdminSchools, getSchoolsFromBackend, isLoadingSchools, hasSelectedSchool, setHasSelectedSchool
		}}>
			{children}
		</AdminModalContext.Provider>
	);
}

function useAdminModalContext() {
	const context = useContext(AdminModalContext);
	if (!context) {
		throw new Error('useModal must be used within a ModalProvider');
	}
	return context;
}

export { useAdminModalContext }
export default AdminModalProvider;