import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider";
import { useUserModalContext } from "../../../Contexts/UserModalContext/UserModalProvider";
import { ViewNoticeMainDataInterface } from "../../../Interfaces/ComponentsInterfaces/SharedInterfaces";
import { CommentInterface, NoticeInterface } from "../../../Interfaces/Entities";
import { TranslateClassnameForNotice, TranslateNoticeDate } from "../../../Helpers/TranslateHelpers";
import { FetchPostFunction, FetchPutFunction } from "../../../Helpers/FetchHelpers";
import '../SharedComponents.css'
import { FormatCommentsForNewComment } from "../../../Helpers/FormatHelpers";


function ViewNoticeMainData({ }: ViewNoticeMainDataInterface) {

	const { schoolOfUser, user } = useGeneralContext()
	const { allNotices, setAllNotices, setNoticeToSee, noticeToSee } = useUserModalContext()
	const cssCode = TranslateClassnameForNotice(noticeToSee, `${noticeToSee.solved ? 'Resolved' : 'UnResolved'}`, 
		schoolOfUser)
	const correctLocationString = noticeToSee.location ? ` - ${noticeToSee.location}` : ``
	const isOwner = user ? user.SK === noticeToSee.user_id : false
	const canFastComment = noticeToSee.type !== 'General' && !noticeToSee.solved

	const handleResolve = async () => {
		if (!schoolOfUser) {
			return
		}
		try {
			const url = `${process.env.REACT_APP_BACKEND_URL}/news`
			const body = {
				'SK': noticeToSee.SK,
				'school_id': schoolOfUser.SK,
				'attrs': {
					'solved': !noticeToSee.solved
				}
			}
			await FetchPutFunction(url, body)
			if (noticeToSee.solved) {
				alert('Se a actualizado la noticia, ahora esta marcada como no resuelta')
			} else {
				alert('Se a actualizado la noticia, ahora esta marcada como resuelta')
			}
			updateNotices()
		} catch (error) {
			alert('Hubo un problema al resolver la noticia')
		}
		
	}

	const updateNotices = () => {
		const updatedNotice = { ...noticeToSee, solved: !noticeToSee.solved }
		const updatedNotices = allNotices.map((notice: NoticeInterface) =>
			notice.SK === noticeToSee.SK ? updatedNotice : notice
		)
		setNoticeToSee(updatedNotice)
		setAllNotices(updatedNotices)
	}
	
	const createFastComment = async (commmentText : string) => {
		try {
			if (!schoolOfUser || !user) {
				return
			}
			const fastComment : CommentInterface = {
				comment: commmentText,
				user_id: user.SK,
				user_name: user.name
			}
			const url = `${process.env.REACT_APP_BACKEND_URL}/comments?`
			const previusComments = FormatCommentsForNewComment(noticeToSee.comments, schoolOfUser.SK)
			const body = {
				SK: noticeToSee.SK,
				school_id: schoolOfUser.SK,
				attrs: {
					comments: [...previusComments, fastComment]
				},
				notify: noticeToSee.notify ? noticeToSee.notify : false,
				notify_attrs: {
					type: noticeToSee.type,
					groups: noticeToSee.groups,
					user: user.name,
					description: noticeToSee.description,
				}
			}
			await FetchPostFunction(url, body)
			updateNoticesForComment(fastComment)
		} catch (error) {
			alert('Hubo un problema al crear un comentario rápido')
		}
	}

	const updateNoticesForComment = (newComment : CommentInterface) => {
		const updatedNotice = { ...noticeToSee, comments: [...noticeToSee.comments, newComment] }
		const updatedNotices = allNotices.map((notice: NoticeInterface) =>
			notice.SK === noticeToSee.SK ? updatedNotice : notice
		)
		setNoticeToSee(updatedNotice)
		setAllNotices(updatedNotices)
	}

	return (
		<>
			<div className="NoticeOwnersData BottomLine">
				<h4>Mensaje de {noticeToSee.user_id.split('@')[0]}</h4>
				<h4>{TranslateNoticeDate(noticeToSee)}</h4>
			</div>
			<div className={`NoticeMainData ${cssCode}Transparent`}>
				<div className="NoticeInternalData">
					<div className="NoticeSubInternalData">
						<h4><strong>{noticeToSee.type}{correctLocationString}</strong></h4>
						{noticeToSee.title ? (
							<>
								<h1>{noticeToSee.title}</h1>
								<h3>{noticeToSee.description}</h3>
							</>
						) : (
							<h1>{noticeToSee.description}</h1>
						)}
					</div>
					<div hidden={!canFastComment} className="FastCommentDiv">
						{noticeToSee.comments.map((comment: CommentInterface) => {
							if (comment.comment === 'Voy ahora') {
								return (
									<div className={`BasicFastComment FastPositiveComment${cssCode}`}>
										{comment.user_name} va ahora
									</div>
								)
							}
							if (comment.comment === 'No puedo ir') {
								return (
									<div className={`BasicFastComment FastNegativeComment${cssCode}`}>
										{comment.user_name} no puede ir
									</div>
								)
							}
							if (comment.comment === 'Voy en breve') {
								return (
									<div className={`BasicFastComment FastPositiveComment${cssCode}`}>
										{comment.user_name} va despues
									</div>
								)
							}
						})}
					</div>
				</div>
			</div>
			<div className="OptionButtonDiv BottomLine" hidden={!canFastComment}>
				<div className={`OptionButtonLeftDiv ${cssCode}CommentButton`} hidden={!canFastComment}>
					<button type='button' onClick={() => createFastComment('Voy ahora')} hidden={isOwner || !canFastComment}>
						Voy Ahora
					</button>
					<button type='button' onClick={() => createFastComment('Voy en breve')} 
						hidden={isOwner || !canFastComment}>
						En Breve
					</button>
					<button type='button' onClick={() => createFastComment('No puedo ir')}  
						hidden={isOwner || !canFastComment}>
						No puedo ir
					</button>
				</div>
				<div className="OptionButtonRightDiv">
					<button hidden={noticeToSee.type === 'General'} 
						onClick={() => handleResolve()}>
						{!noticeToSee.solved ? 'Resuelto' : 'No Resuelto'}
					</button>
				</div>
			</div>
		</>
	)
}

export default ViewNoticeMainData