import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGeneralContext } from "../../Contexts/GeneralContext/GeneralProvider";
import NavBar from "../../Others/NavBar/NavBar";
import SummarySuperiorSchool from "../../Components/SharedComponents/SummaryData/SummarySuperiorSchool";
import UserGroupListModal from "./Modals/UserGroupListModal";
import UserProfileModal from "./Modals/UserProfileModal";
import UserHomeModal from "./Modals/UserHomeModal";
import UserHistoryModal from "./Modals/UserHistoryModal";
import UserSeeNoticeModal from "./Modals/UserSeeNoticeModal";
import UserCreateNewsLetterModal from "./Modals/UserCreateNewsLetterModal";
import UserCreateNoticeModal from "./Modals/UserCreateNoticeModal";
import UserSeeGroupModal from "./Modals/UserSeeGroupModal";
import UserReportsModal from "./Modals/UserReportsModal";
import UserCreateGrupalReportModal from "./Modals/UserCreateGrupalReportModal";
import UserCreateIndividualReportModal from "./Modals/UserCreateIndividualReportModal";
import { TranslateModalName } from "../../Helpers/TranslateHelpers";
import './UserModalCSS.css'
import '../../index.css'



function UserModalContainer() {

	const { schoolOfUser, hasLogged, currentModal } = useGeneralContext()
	const navigate = useNavigate()

	useEffect(() => {
		if (!hasLogged) {
			navigate('/')
		}
	}, [])

	return (
		<div className="ModalContainerMainDiv">
			<NavBar />
			<div className="ConteinerModalShower">
				<SummarySuperiorSchool
					schoolToSummary={schoolOfUser}
				/>
				<div className="ModalTitleDiv">
					<h1>{TranslateModalName(currentModal)}</h1>
				</div>
				<div className="ModalDisplayer">
					{
						(() => {
							switch (currentModal) {
								case 'HomeModal':
									return <UserHomeModal />
								case 'NoticeList':
									return <UserHistoryModal />
								case 'SeeNotice':
									return <UserSeeNoticeModal />
								case 'GroupList':
									return <UserGroupListModal />
								case 'Profile':
									return <UserProfileModal />
								case 'CreateNotice':
									return <UserCreateNoticeModal />
								case 'CreateNewsLetter':
									return <UserCreateNewsLetterModal />
								case 'SeeGroup':
									return <UserSeeGroupModal />
								case 'HomeReport':
									return <UserReportsModal />
								case 'GetIndividualReport':
									return <UserCreateIndividualReportModal />
								case 'GetGrupalReport':
									return <UserCreateGrupalReportModal />
								default:
									return <UserHomeModal />
							}
						})()
					}
				</div>
			</div>
		</div>
	)


}

export default UserModalContainer