import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { signIn, signOut } from "aws-amplify/auth"
import { useGeneralContext } from "../../Contexts/GeneralContext/GeneralProvider"
import LoadingInfo from "../../Components/SharedComponents/OtherComponents/LoadingInfo"
import { LoginModalInterface } from "../../Interfaces/ComponentsInterfaces/LoginPageInterface"
import LinkClassLogo from '../../Assets/LinkClassLogo.png'
import '../../Views/LoginPage/LoginPage.css'


function LoginModal({ setLoginModal, setLoginUsername }: LoginModalInterface) {

	const { handleLogin } = useGeneralContext()

	const navigate = useNavigate()

	const [username, setUsername] = useState<string>('')
	const [password, setPassword] = useState<string>('')
	const [isRevealingPassword, setIsRevealingPassword] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)


	const handleUserchange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault()
		setUsername(e.target.value)
	}

	const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault()
		setPassword(e.target.value)
	}

	const handleLoginForModal = async () => {
		try {
			if (username.trim() === '' || password.trim() === '') {
				alert('Nombre de usuario o contraseña incompleto')
				return
			}
			setIsLoading(true)
			const response = await signIn({ username: username, password: password });
			setLoginUsername(username)
			if (response.isSignedIn) {
				await handleLogin(username, true)
			} else {
				if (response.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
					setLoginModal('ConfimPassword')
				}
			}
		} catch (err) {
			console.log(err)
			await signOut({ global: true })
			setIsLoading(false)
			alert('Nombre de usuario o contraseña incorrectos.');
		}
	};

	useEffect(() => {
		signOut({ global: true })
	}, [])

	const handleReturnToLanding = () => {
		navigate('/')
	}


	return (
		<div className="InternalDiv">
			<div className="LoginLogoDiv" onClick={handleReturnToLanding}>
				<img src={LinkClassLogo} alt="LinkClassLogo" />
			</div>
			{isLoading ? (
				<LoadingInfo />
			) : (
				<>
					<h1>Inicio de sesión</h1>
					<div className="LoginInputDiv">
						<input
							type='text'
							placeholder="Usuario..."
							value={username}
							onChange={handleUserchange}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									e.preventDefault();
								}
							}}
						/>
					</div>
					<div className="LoginPassWordInput">
						<input
							type={isRevealingPassword ? "text" : "password"}
							value={password}
							placeholder="Contraseña"
							onChange={handlePasswordChange}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									e.preventDefault();
								}
							}}
						/>
						<button type="button" onClick={() => setIsRevealingPassword(!isRevealingPassword)}>
							{isRevealingPassword ? "Ocultar Contraseña" : "Mostrar contraseña"}
						</button>
					</div>
					<div className="ForgetPassWordDiv">
						<button type="button" onClick={() => setLoginModal('LostPassword')}>
							Olvide mi contraseña
						</button>
					</div>
					<div className="FinalButtonDiv">
						<button type="button" onClick={handleLoginForModal}>
							Iniciar Sesion
						</button>
					</div>
				</>
			)}
		</div>
	)
}

export default LoginModal