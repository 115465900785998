export function isDateInThisWeek(dateString: string): boolean {
	const today = new Date();
	const dayOfWeek = today.getUTCDay(); // Usar getUTCDay() para obtener la hora UTC
	const differenceToMonday = (dayOfWeek === 0 ? 6 : dayOfWeek - 1);
	const firstDayOfWeek = new Date(Date.UTC(today.getUTCFullYear(),
		today.getUTCMonth(), today.getUTCDate() - differenceToMonday));
	firstDayOfWeek.setUTCHours(0, 0, 0, 0);
	const lastDayOfWeek = new Date(firstDayOfWeek);
	lastDayOfWeek.setUTCDate(firstDayOfWeek.getUTCDate() + 6);
	lastDayOfWeek.setUTCHours(23, 59, 59, 999);
	const dateToCheck = CreateDateFromNoticeDate(dateString)
	return dateToCheck >= firstDayOfWeek && dateToCheck <= lastDayOfWeek && !AreTwoDatesTheSameDay(dateToCheck, today);
}

export function AreTwoDatesTheSameDay(date1: Date, date2: Date): boolean {
	return (
		date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getUTCDate() === date2.getUTCDate()
	);
}

export function CreateDateFromNoticeDate(dateString: string): Date {
	if (!dateString) {
		const now = new Date();
		const year = now.getFullYear();
		const month = now.getMonth();
		const day = now.getDate();
		return new Date(year, month, day, 0, 0, 0);
	}
	if (dateString.includes(" ")) /* La fecha incluye hora */ {
		const [year, month, day] = dateString.split(" ")[0].split('-').map(Number);
		if (year > 1000) {
			return new Date(year, month - 1, day, 0, 0, 0)
		} else {
			return new Date(day, month - 1, year, 0, 0, 0)
		}
	} else {
		const [year, month, day] = dateString.split('-').map(Number);
		if (year > 1000) {
			return new Date(year, month - 1, day, 0, 0, 0)
		} else {
			return new Date(day, month - 1, year, 0, 0, 0)
		}
	}
}

export function FormatDate(currentDate: Date): string {
	const formattedDate = currentDate.getFullYear() + "-" +
		String(currentDate.getMonth() + 1).padStart(2, '0') + "-" +
		String(currentDate.getDate()).padStart(2, '0') + " " +
		String(currentDate.getHours()).padStart(2, '0') + ":" +
		String(currentDate.getMinutes()).padStart(2, '0') + ":" +
		String(currentDate.getSeconds()).padStart(2, '0') + "." +
		String(currentDate.getMilliseconds()).padStart(3, '0');
	return formattedDate;
}

export function GetDayFromNow(dateChanger: number): string {
	const currentDate = new Date()
	currentDate.setDate(currentDate.getDate() + dateChanger)
	return FormatDate(currentDate)
}