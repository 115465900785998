import React, { useState } from "react"
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider"
import { GroupInterface } from "../../../Interfaces/Entities"
import { TranslateClassnameForButton } from "../../../Helpers/TranslateHelpers"
import { FetchPostFunction } from "../../../Helpers/FetchHelpers"
import { FormatDate } from "../../../Helpers/DateHelpers"



function UserCreateGrupalReportModal() {

	const { schoolOfUser, groupsOfUserAsGroups, user, setCurrentModal } = useGeneralContext()
	const correctSchoolTypes = schoolOfUser ? schoolOfUser.types.filter(item => item !== 'General') : []

	const [reportTitle, setReportTitle] = useState<string>('')
	const [reportGroupName, setReportGroupName] = useState<string>(groupsOfUserAsGroups[0].name || '')
	const [reportGroup, setReportGroup] = useState<GroupInterface | undefined>(groupsOfUserAsGroups[0] || undefined)
	const [reportStartDate, setReportStartDate] = useState<string>('')
	const [reportEndDate, setReportEndDate] = useState<string>('')
	const [reportTypes, setReportTypes] = useState<string[]>([])

	const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setReportTitle(e.target.value);
	};

	const handleReportGroup = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setReportGroupName(e.target.value);
		const filteredGroups = groupsOfUserAsGroups.find((group) => group.SK === e.target.value)
		if (filteredGroups) {
			setReportGroup(filteredGroups)
		}
	};

	const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setReportStartDate(e.target.value)
	};

	const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setReportEndDate(e.target.value)
	};

	const handleStateButtonClick = (buttonCode: string) => {
		const updatedTypes = reportTypes.includes(buttonCode)
			? reportTypes.filter(item => item !== buttonCode)
			: [...reportTypes, buttonCode];
		setReportTypes(updatedTypes)
	}

	const handleSubmit = async () => {
		if (!schoolOfUser || !user) {
			return
		}
		if (reportTitle.trim() === '') {
			alert('El titulo no puede ser vacio')
			return
		}
		if (!reportGroup) {
			alert('Debe elegir un grupo')
			return
		}
		if(reportStartDate.trim() === '') {
			alert('Debe seleccionar una fecha de inicio')
			return
		}
		if(reportEndDate.trim() === '') {
			alert('Debe seleccionar una fecha de termino')
			return
		}
		try {
			const url = `${process.env.REACT_APP_BACKEND_URL}/group-report/${reportGroup.SK}`;
			const body = {
				'title': reportTitle,
				'startDate' : FormatDate(new Date(reportStartDate)).split(" ")[0],
				'endDate' : FormatDate(new Date(reportEndDate)).split(" ")[0],
				'school_id': schoolOfUser.SK,
				'email': user.SK
			}
			await FetchPostFunction(url, body)
			alert(`Le llegara el reporte a ${user.SK} dentro de los proximos 15 minutos`)
			setCurrentModal('HomeReport')
		} catch (error) {
			console.log(error)
			alert('Hubo un problema al crear un reporte')
		}
	}

	return (
		<div className="CreateIndividualReportMainDiv">
			<div className="CreateIndividualReportDataField">
				<h3>Título del informe</h3>
				<input
					type="text"
					id='title'
					name='title'
					value={reportTitle}
					onChange={handleTitleChange}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
			</div>
			<div className="CreateIndividualReportDataField">
				<h3>Nombre del Grupo</h3>
				<select
					className="ReportGroupSelect"
					value={reportGroupName}
					onChange={handleReportGroup}
				>
					{groupsOfUserAsGroups.map((group : GroupInterface) => (
						<option key={group.SK} value={group.SK}>{group.name}</option>
					))}
				</select>
			</div>
			<div className="CreateIndividualReportDateField">
				<h3>Fecha del informe</h3>
				<div className="CreateIndividualReportDateMainField">
					<div className="CreateIndividualReportDateSubField">
						<div className="CreateIndividualReportIndividualDate">
							<h4>Desde:</h4>
							<input
								type="date"
								value={reportStartDate}
								onChange={handleStartDateChange}
								max={reportEndDate}
							/>
						</div>
						<div className="CreateIndividualReportIndividualDate">
							<h4>Hasta:</h4>
							<input
								type="date"
								value={reportEndDate}
								onChange={handleEndDateChange}
								min={reportStartDate}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="CreateIndividualReportTypeField">
				<h3>Situaciones crítica</h3>
				<div className="CreateIndividualReportTypeBox">
					{correctSchoolTypes.map((type: string) => (
						<button
							type='button' onClick={() => handleStateButtonClick(type)}
							className={reportTypes.includes(type) ?
								`ReportTypeSelected  ${TranslateClassnameForButton(type, schoolOfUser)}` :
								'ReportTypeUnSelected'
							}
						>
							{type}
						</button>
					))}
				</div>
			</div>

			<button type='button' className="CreateReportButton" onClick={handleSubmit}>
				Crear Informe
			</button>
		</div>
	)
}

export default UserCreateGrupalReportModal