import UserModalProvider from "../../Contexts/UserModalContext/UserModalProvider";
import UserModalSelecter from "../../Modals/UserModals/UserModalSelecter";
import UserModalContainer from "../../Modals/UserModals/UserModalContainer";
import '../../index.css'



function UserPage() {

	return (
		<div className="PageMainDiv">
			<UserModalProvider>
				<div className="PageLeftSide">
					<UserModalSelecter />
				</div>
				<div className="PageRightSide">
					<UserModalContainer />
				</div>
			</UserModalProvider>
		</div>
	)
}

export default UserPage;