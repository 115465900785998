import { useEffect, useState } from "react";
import { useAdminModalContext } from "../../../Contexts/AdminModalContext/AdminModalProvider";
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider";
import { SchoolListModalInterface } from "../../../Interfaces/ComponentsInterfaces/AdministratorInterfaces";
import { SchoolInterface } from "../../../Interfaces/Entities";
import ViewSingularSchool from "../../../Components/AdminModalComponents/ViewData/ViewSingularSchool";
import LoadingInfo from "../../../Components/SharedComponents/OtherComponents/LoadingInfo";
import CreateSchoolButton from "../../../Components/AdminModalComponents/CreateData/CreateSchoolButton";
import { FormatSchoolsForSuperAdmin } from "../../../Helpers/FormatHelpers";
import "../AdminCSS.css"


function SchoolListModal({ setSchoolToEdit }: SchoolListModalInterface) {

	const { adminSchools, getSchoolsFromBackend, isLoadingSchools } = useAdminModalContext()
	const { currentModal } = useGeneralContext()
	const [schoolRows, setSchoolRows] = useState<(SchoolInterface | "CreateSchool" | undefined)[][]>(
		FormatSchoolsForSuperAdmin(adminSchools)
	)

	useEffect(() => {
		if (adminSchools.length === 0 && currentModal === "SchoolList") {
			getSchoolsFromBackend();
		}
	}, [currentModal]);

	useEffect(() => {
		setSchoolRows(FormatSchoolsForSuperAdmin(adminSchools))
	}, [adminSchools]);

	return (
		<div className="AdminModalMainDiv">
			{isLoadingSchools ? (
				<LoadingInfo />
			) : (
				<>
					<div className="SchoolListMapDiv">
						{schoolRows.map((row, rowIndex) => (
							<div className="SchoolListMapDivRow" key={rowIndex}>
								{row.map((element) => (
									element === "CreateSchool" ? (
										<CreateSchoolButton />
									) : (
										<ViewSingularSchool
											schoolToShow={element}
											setSchoolToEdit={setSchoolToEdit}
										/>
									)
								))}
							</div>
						))}
					</div>
				</>
			)}
		</div>
	);
}

export default SchoolListModal