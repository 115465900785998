import React, { useEffect, useState } from "react";
import { useGeneralContext } from "../../../Contexts/GeneralContext/GeneralProvider";
import { useSchoolAdminModalContext } from "../../../Contexts/SchoolAdminContext/SchoolAdminProvider";
import { GroupInterface, StudentInterface } from "../../../Interfaces/Entities";
import SearchGroupAdmin from "../../../Components/SchoolAdminModalsComponents/SearchData/SearchGroupAdmin";
import { FetchPutFunction } from "../../../Helpers/FetchHelpers";


function SchoolAdminEditStudentModal() {

	const { schoolOfUser, setCurrentModal } = useGeneralContext()
	const { schoolGroups, setSchoolGroups, studentToEdit } = useSchoolAdminModalContext()
	const correctStudentName = studentToEdit ? studentToEdit.name : ''

	const [studentName, setStudentName] = useState<string>(correctStudentName)
	const [studentGroups, setStudentsGroups] = useState<GroupInterface[]>([])

	const [newGroups, setNewGroups] = useState<string[]>([])
	const [deletedGroups, setDeletedGroups] = useState<string[]>([])

	const handleEdditNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const searchValue = e.target.value;
		setStudentName(searchValue);
	};

	const handleStudentCreation = async () => {
		if (!schoolOfUser) {
			return
		}
		if (!studentToEdit) {
			return
		}
		if (studentName.trim() === '') {
			alert('El nombre no puede estar vacio')
			return
		}
		if (studentGroups.length === 0) {
			alert('El estudiante debe estar en al menos 1 grupo')
			return
		}
		try {
			const updatedStudent: StudentInterface = { name: studentName, rut: studentToEdit.rut }
			const groupsToEdit = getGroupsToEdit()

			for (let index = 0; index < groupsToEdit.length; index++) {
				const currentGroupSK = groupsToEdit[index];
				const currentGroup = schoolGroups.find((group) => group.SK === currentGroupSK)
				if (currentGroup) {
					const url = `${process.env.REACT_APP_BACKEND_URL}/groups/${currentGroupSK}`;
					let updatedStudents: StudentInterface[] = [];

					if (newGroups.includes(currentGroupSK)) {
						updatedStudents = [...currentGroup.students, updatedStudent];
					} else if (deletedGroups.includes(currentGroupSK)) {
						updatedStudents = currentGroup.students.filter(
							(student: StudentInterface) => student.rut !== studentToEdit.rut
						);
					} else {
						updatedStudents = currentGroup.students.map(
							(student: StudentInterface) => student.rut === studentToEdit.rut ? updatedStudent : student
						);
					}

					const body = { 'attrs': { 'students': updatedStudents }, 'school_id': schoolOfUser.SK };
					await FetchPutFunction(url, body)
				}
			}
			const updatedSchoolGroups = updateStudentInGroups(schoolGroups, updatedStudent, newGroups, deletedGroups)
			setSchoolGroups(updatedSchoolGroups)
			setCurrentModal('StudentsAndUsers')
			alert("Estudiante correctamente creado")
		} catch (error) {
			alert("Hubo un problema al crear el estudiante")
		}
	}

	const getGroupsToEdit = () => {
		let groupsSk = studentGroups.map(group => group.SK)
		for (let index = 0; index < newGroups.length; index++) {
			const currentNewGroup = newGroups[index];
			if (!groupsSk.includes(currentNewGroup)) {
				groupsSk = [...groupsSk, currentNewGroup]
			}
		}
		for (let index = 0; index < deletedGroups.length; index++) {
			const element = deletedGroups[index];
			if (!groupsSk.includes(element)) {
				groupsSk = [...groupsSk, element]
			}
		}
		return groupsSk
	}

	const updateStudentInGroups = (schoolGroups: GroupInterface[], student: StudentInterface, newGroups: string[],
		deleteGroups: string[]): GroupInterface[] => {
		return schoolGroups.map(group => {
			const isStudentInGroup = group.students.some(groupStudent => groupStudent.rut === student.rut);

			if (deleteGroups.includes(group.SK)) {
				if (isStudentInGroup) {
					const updatedStudents = group.students.filter(groupStudent => groupStudent.rut !== student.rut);
					return { ...group, students: updatedStudents };
				}
				return group;
			}

			if (newGroups.includes(group.SK)) {
				if (!isStudentInGroup) {
					const updatedStudents = [...group.students, student];
					return { ...group, students: updatedStudents };
				}
				return group;
			}

			if (isStudentInGroup) {
				const updatedStudents = group.students.map(groupStudent =>
					groupStudent.rut === student.rut ? { ...groupStudent, name: student.name } : groupStudent
				);
				return { ...group, students: updatedStudents };
			}

			return group;
		});
	}

	const handleSearchClick = (group: GroupInterface) => {
		if (!studentGroups.includes(group)) {
			const updatedGroups = [...studentGroups, group]
			setStudentsGroups(updatedGroups)
			if (deletedGroups.includes(group.SK)) {
				const updatedDeletedGroups = deletedGroups.filter((groupSk: string) => (
					groupSk !== group.SK
				))
				setDeletedGroups(updatedDeletedGroups)
			} else {
				setDeletedGroups([...newGroups, group.SK])
			}
		}
	}

	const handleGroupDelete = (groupToDelete: GroupInterface) => {
		const updatedGroups = studentGroups.filter((group: GroupInterface) => (
			group.SK !== groupToDelete.SK
		))
		setStudentsGroups(updatedGroups)
		if (newGroups.includes(groupToDelete.SK)) {
			const updatedNewGroups = newGroups.filter((groupSk: string) => (
				groupSk !== groupToDelete.SK
			))
			setNewGroups(updatedNewGroups)
		} else {
			setDeletedGroups([...deletedGroups, groupToDelete.SK])
		}
	}

	useEffect(() => {
		if (!studentToEdit) {
			return
		}
		const updatedStudentsGroups = schoolGroups.filter((group: GroupInterface) => (
			group.students.some((groupStudent: StudentInterface) => groupStudent.rut === studentToEdit.rut)
		))
		setStudentsGroups(updatedStudentsGroups)
	}, [studentToEdit])

	return (
		<div className="CreateStudentMainDiv">
			<div className="CreateStudentTopDiv">
				<h4>Nombre y apellidos</h4>
				<input
					value={studentName}
					onChange={handleEdditNameChange}
					className="CreateStudentNameInput"
				/>
			</div>
			<div className="CreateStudentGroupDiv">
				<h4>Grupos del estudiante</h4>
				<SearchGroupAdmin
					currentGroups={studentGroups}
					handleSearchClick={handleSearchClick}
				/>
				<div className="CreateStudentGroupInternalDiv">
					{studentGroups.length === 0 ? (
						<h4>El estudiante no tiene grupos, debe ingresarlo a al menos un grupo para crearlo</h4>
					) : (
						<div className="CreateStudentMapGroupDiv">
							{studentGroups.map((group: GroupInterface) => (
								<button onClick={() => handleGroupDelete(group)}>
									{group.name}
								</button>
							))}
						</div>
					)}

				</div>
			</div>
			<div className="CreateStudentButtonDiv">
				<button type='button' onClick={() => setCurrentModal('StudentsAndUsers')}>
					Cancelar
				</button>
				<button type='button' onClick={handleStudentCreation}>
					Guardar Cambios
				</button>
			</div>
		</div>
	)
}

export default SchoolAdminEditStudentModal